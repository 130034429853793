import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";

import { useReduxState, useActions } from "store";

import { ConfigProps } from "./confirmBox.duck";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

const dangerWords = ["delete", "remove", "cancel", "reset"];

var openConfirmMethod: (props: ConfigProps) => void;

export default () => {
  const confirmBox = useReduxState((e) => e.confirmBox);
  const actions = useActions().confirmBox;
  const { isOpen, config } = confirmBox;

  React.useEffect(() => {
    if (!openConfirmMethod) {
      openConfirmMethod = (props: ConfigProps) => {
        actions.openConfirm(props);
      };

      //i dont like this, but taking too ling to figure
      //out how to get this to play nicely
      //this will call cancel on every page change
      //this is what happens in js version of ATools as well
      // hashHistory.listen((e) => {
      //   actions.cancelConfirm();
      // });
    }
  }, []);

  if (!confirmBox || !isOpen) {
    return <div />;
  }
  let isDanger = false;
  var okText = !config.okBtnText ? "OK" : config.okBtnText;
  var cancelText = !config.cancelBtnText ? "Cancel" : config.cancelBtnText;

  for (var i = 0; i < dangerWords.length; i++) {
    if (
      (config.title &&
        config.title.toLowerCase().indexOf(dangerWords[i]) !== -1) ||
      (config.description &&
        htmlStrip(config.description).toLowerCase().indexOf(dangerWords[i]) !==
          -1)
    ) {
      isDanger = true;
      break;
    }
  }

  const okClick = () => {
    confirmBox.config.callback?.();
    actions.okConfirm();
  };

  return (
    isOpen && (
      <Dialog
        open
        style={{ zIndex: 10000 }}
        maxWidth={config.maxWidth}
        fullWidth={config.fullWidth}
        PaperProps={
          config.overflowYVisible ? { style: { overflowY: "visible" } } : {}
        }
      >
        {config.title && <DialogTitle>{config.title}</DialogTitle>}
        <DialogContent style={{ paddingTop: 10, paddingBottom: 20 }}>
          <Typography>{config.description}</Typography>
        </DialogContent>
        <DialogActions>
          {isDanger ? (
            <div>
              <DangerButton variant="contained" onClick={okClick} autoFocus>
                {okText}
              </DangerButton>
            </div>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={okClick}
              autoFocus
            >
              {okText}
            </Button>
          )}
          {!config.isAlert && (
            <Button
              variant="text"
              color="primary"
              onClick={() => actions.cancelConfirm()}
            >
              {cancelText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  );
};

function htmlStrip(html: string | JSX.Element) {
  let div = document.createElement("div");
  div.innerHTML = html.toString();
  return div.textContent || div.innerText || "";
}

export const openConfirm = (props: ConfigProps) => {
  if (openConfirmMethod) {
    openConfirmMethod(props);
  }
};

const DangerButton = styled(Button)`
  color: white;
  background-color: ${red[700]};
  :hover {
    background-color: ${red[900]};
  }
`;

export type Props = ConfigProps;
