import { Button, Grid, Typography } from "@material-ui/core";
import { RequisitionData } from "common/components/types";
import { ReactInstance, RefObject, useRef } from "react";
import PrintSharpIcon from "@material-ui/icons/PrintSharp";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import PatientList from "common/components/PatientList";
import FinishSkeleton from "./FinishSkeleton";

export type Props = {
  requisitionList: RequisitionData[];
  isMarkingList?: boolean;
  onEditClick?(): void;
};

const List = (props: Props) => {
  return (
    <div>
      <PatientList requisitionList={props.requisitionList} />
    </div>
  );
};

const PrintList = (props: Props) => {
  const printRef = useRef<ReactInstance>(null);
  return (
    <div>
      <div>
        <div
          style={{ marginLeft: 150, marginTop: props.isMarkingList ? 100 : 0 }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 50 }}
            onClick={props.onEditClick}
          >
            <ArrowBackSharpIcon style={{ marginRight: 10 }} />
            Back
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary">
                <PrintSharpIcon style={{ marginRight: 10 }} />
                Print
              </Button>
            )}
            content={() => printRef.current}
            documentTitle={
              props.isMarkingList ? "Marking List" : "Test Collection List"
            }
          />
        </div>
        <div ref={printRef as RefObject<HTMLDivElement>}>
          <StyledGrid>
            <Typography variant="h5">
              {props.isMarkingList ? "Marking List" : "TEST COLLECTION LIST"}
            </Typography>
          </StyledGrid>
          <StyledGrid>
            {props.requisitionList.length === 0 && <FinishSkeleton />}
            <List requisitionList={props.requisitionList} />{" "}
          </StyledGrid>
        </div>
      </div>
    </div>
  );
};

export default PrintList;

const StyledGrid = styled(Grid)`
  margin: auto;
  margin-top: 25px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
