import { createSlice } from "@reduxjs/toolkit";
import {
  createAsyncAction,
  ReducerState,
} from "@nait-aits/redux";
import { CurrentUser } from "common/components/types";
import getAuthBearerToken from "store/getAuthBearerToken";

const controlName = "mainTemplate";

type State = {
  currentUser: ReducerState<CurrentUser>;
};

const baseURL = `${process.env.REACT_APP_API_BASE}/settings`

var checkUserAccess = createAsyncAction<CurrentUser, { userName: string}, State>({
  actionPrefix: controlName,
  actionName: "checkUserAccess",
  url: baseURL + "/CheckCurrentUserAccess",
  pending: (state, action) => {
    state.currentUser.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.currentUser.isLoading = false;
    state.currentUser.data = action.payload;
  },
  rejected: (state, action) => {},
  getAuthBearerToken
});

var slice = createSlice({
  name: controlName,
  initialState: {
    currentUser: { isLoading: true },
  } as State,
  reducers: {},
  extraReducers: {
    ...checkUserAccess.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      checkUserAccess: checkUserAccess.action,
    },
  },
};
export default ret