function LookupLabel(key: string) {
  var res: string = "";
  switch (key) {
    case "firstName":
      res = "First Name";
      break;

    case "lastName":
      res = "Last Name";
      break;

    case "sex":
      res = "Sex";
      break;

    case "dateOfBirth":
      res = "Date Of Birth";
      break;

    case "postalCode":
      res = "Postal Code";
      break;

    case "orderingPhysician":
      res = "Ordering Physician";
      break;

    case "physicianCode":
      res = "Physician Code";
      break;

    case "collectionDate":
      res = "Collection Date";
      break;

    case "collectionTime":
      res = "Collection Time";
      break;

    case "collectionLocation":
      res = "Collection Location";
      break;

    case "toCollect":
      res = "To Collect";
      break;

    case "testCodes":
      res = "Test Codes";
      break;

    default:
      res = key;
      break;
  }

  return res;
}

export default LookupLabel;
