import styled from "styled-components";
import React from "react";
import { useBarcode } from "react-barcodes";
import { RequisitionData } from "common/components/types";
export type Props = {
  value?: string;
  requisitionData: RequisitionData;
  className?: string;
};

const SidePatientData: React.FC<Props> = ({
  value,
  requisitionData,
  className,
}) => {
  return (
    <div className={className}>
      <div>{value}</div>
      <div>
        {requisitionData.lastName?.toUpperCase()} {requisitionData.firstName}
      </div>
      <div>
        {requisitionData.phn} {requisitionData.sex}
      </div>
      <div>
        {requisitionData.collectionDate?.toUpperCase()}{" "}
        {requisitionData.collectionTime}
      </div>
    </div>
  );
};

const PatientCareBarcode: React.FC<Props> = ({ requisitionData, value }) => {
  const defaultVal = "0000000000";
  const { inputRef } = useBarcode({
    value: value ? value : defaultVal,
    options: {
      displayValue: false,
      background: "#ffffff",
      height: 50,
    },
  });
  return (
    <div>
      <BarcodeContainer>
        <StyledBarcodeNumber>{value || defaultVal}</StyledBarcodeNumber>
        <div style={{ padding: 5 }}>
          <div style={{ marginLeft: 10 }}>
            <div style={{ fontSize: 20 }}>
              {requisitionData.lastName?.toUpperCase()}{" "}
              {requisitionData.firstName}
            </div>
            <div style={{ wordWrap: "break-word" }}>
              {requisitionData.phn} {requisitionData.sex}{" "}
              {requisitionData.dateOfBirth}
            </div>
            <div>{requisitionData.collectionLocation?.toUpperCase()}</div>
            <div>
              {requisitionData.collectionDate?.toUpperCase()}{" "}
              {requisitionData.collectionTime}
            </div>
          </div>
          <svg ref={inputRef} />
          <div style={{ marginLeft: 10 }}>
            {requisitionData.testCodes?.map((c) => c.code).join(" ")}
          </div>
        </div>
        <div style={{ borderLeft: "dotted 1px gray", width: "100%" }}>
          <StyledSidePatientData
            value={value ? value : defaultVal}
            requisitionData={requisitionData}
          />
          <div style={{ borderBottom: "dotted 1px gray", width: "100%" }}></div>
          <StyledSidePatientData
            value={value ? value : defaultVal}
            requisitionData={requisitionData}
          />
        </div>
      </BarcodeContainer>
    </div>
  );
};

const BarcodeContainer = styled.div`
  border: dotted 1px gray;
  border-radius: 4px;
  display: flex;
  font-family: "Roboto Mono", monospace;
  font-size: 12px;
  margin-top: 10px;
  width: 360px;
  word-wrap: break-word;
`;

const StyledSidePatientData = styled(SidePatientData)`
  margin: 5px;
  width: 70px;
`;

const StyledBarcodeNumber = styled.div`
  writing-mode: vertical-rl;
  text-orientation: sideways;
  transform: rotate(180deg);
  margin-top: auto;
  margin-bottom: auto;
  font-size: 18px;
  padding: 5px;
`;

export default PatientCareBarcode;
