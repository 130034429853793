import { useEffect, useState } from "react";
import DialogBox from "common/dialogs/DialogBox/DialogBox";
import { RequisitionData } from "common/components/types";
import {
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import DocIcon from "@material-ui/icons/Description";
import fillAndDownloadRequisition from "common/components/FillAndDownloadRequisition";

export type Props = {
  open: boolean;
  onClose(): void;
  requisitionList: RequisitionData[];
};

type State = {
  selectedRequisition: RequisitionData | undefined;
  okButtonText: string;
  disableDialogControls: boolean;
};

const DownloadRequisitionsDialog = (props: Props) => {
  const { open, onClose, requisitionList } = props;
  const [state, setState] = useState<State>({
    selectedRequisition: requisitionList[0],
    okButtonText: "Ok",
    disableDialogControls: false,
  });

  useEffect(() => {
    setState({ ...state, selectedRequisition: requisitionList[0] });
  }, [requisitionList]);

  return (
    <DialogBox
      open={open}
      dialogTitle={"Select a requisition"}
      dialogContent={
        <div>
          <AllRequisitionsList
            onSelectRequisition={(c) =>
              setState({ ...state, selectedRequisition: c })
            }
            allReqList={requisitionList}
          />
        </div>
      }
      agreeButtonLabel={state.okButtonText}
      disableControls={state.disableDialogControls}
      agreeButtonAction={() => {
        setState({
          ...state,
          okButtonText: "Downloading...",
          disableDialogControls: true,
        });
        fillAndDownloadRequisition(state.selectedRequisition).then(
          (response) => {
            setState({
              ...state,
              okButtonText: "Ok",
              disableDialogControls: false,
            });
            onClose();
          }
        );
      }}
      disagreeButtonLabel={"Cancel"}
      disagreeButtonAction={() => {
        onClose();
      }}
      onClose={onClose}
    />
  );
};
type ListProps = {
  onSelectRequisition(c: RequisitionData): void;
  allReqList: RequisitionData[];
};
const AllRequisitionsList = ({
  onSelectRequisition,
  allReqList,
}: ListProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  if (allReqList.length === 0) return <CircularProgress />;
  return (
    <div>
      {allReqList.map((c, index) => (
        <ListItem
          key={index}
          selected={selectedIndex === index}
          button
          onClick={() => {
            setSelectedIndex(index);
            onSelectRequisition(c);
          }}
        >
          <ListItemIcon>
            <DocIcon />
          </ListItemIcon>
          <ListItemText primary={`${c.phn} - ${c.firstName} ${c.lastName}`} />
        </ListItem>
      ))}
    </div>
  );
};
export default DownloadRequisitionsDialog;
