import { createSlice } from "@reduxjs/toolkit";
import {
  createAsyncAction,
  ReducerState,
} from "@nait-aits/redux";
import { ReqNameAndPhnItem, RequisitionData } from "common/components/types";
import getAuthBearerToken from "store/getAuthBearerToken";

const controlName = "manageRequisition";

const baseURL = `${process.env.REACT_APP_API_BASE}/requisition`
// type Return = {};
// type Data = ReducerState<Return>;

//this is the type of request to server call for Add Requisition
type AddRequisitionPostData = {
  newRequisition: RequisitionData;
  onComplete?: () => void;
};

//this is the type of request to server call
type GetRequisitionPostData = {
  phn: string;
};

var getPracticeRequisitions = createAsyncAction<
  RequisitionData[], //return type
  {}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getPracticeRequisitions",
  url: baseURL + "/GetPracticeRequisitions",
  pending: (state, action) => {
    state.practiceRequisitionList = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.practiceRequisitionList.isLoading = false;
    state.practiceRequisitionList.data = action.payload;
  },
  rejected: (state, action) => {
    state.practiceRequisitionList.isLoading = false;
    state.practiceRequisitionList.error = action.payload;
  },
  getAuthBearerToken
});

var getRequisition = createAsyncAction<
  RequisitionData, //return type
  GetRequisitionPostData, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getRequisition",
  url: baseURL + "/GetRequisition",
  pending: (state, action) => {
    state.getRequisition = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.getRequisition.isLoading = false;
    state.getRequisition.data = action.payload;
  },
  rejected: (state, action) => {
    state.getRequisition.isLoading = false;
    state.getRequisition.error = action.payload;
  },
  getAuthBearerToken
});

var addRequisition = createAsyncAction<
  { testCodeFound: boolean; newRequisition: RequisitionData }, //return type
  AddRequisitionPostData, //params
  State
>({
  actionPrefix: controlName,
  actionName: "addNewRequisition",
  url: baseURL + "/AddNewRequisition",
  pending: (state, action) => {
    state.addRequisition.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.addRequisition.isLoading = false;
    state.addRequisition.data = action.payload.newRequisition;
    const newList = state.allRequisitionList.data;
    newList && newList.unshift(action.payload.newRequisition);
    state.allRequisitionList.data = newList;
  },
  rejected: (state, action) => {
    state.addRequisition.isLoading = false;
    state.addRequisition.error = action.payload;
  },
  onComplete: (payload) => {
    payload.params.onComplete && payload.params.onComplete();
  },
  getAuthBearerToken
});

var deleteRequisition = createAsyncAction<
  RequisitionData,
  //{ result: boolean; newList: RequisitionData[] },
  { id: number },
  State
>({
  actionPrefix: controlName,
  actionName: "deleteRequisition",
  url: baseURL + "/DeleteRequisition",
  pending: (state, action) => {
    state.deleteRequisition.isLoading = true;
    state.allRequisitionList.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.deleteRequisition.isLoading = false;
    state.deleteRequisition.data = action.payload;
    state.allRequisitionList.isLoading = false;
    state.allRequisitionList.data = state.allRequisitionList.data?.filter(
      (c) => c.id !== action.payload.id
    );
  },
  rejected: (state, action) => {
    state.deleteRequisition.isLoading = false;
    state.deleteRequisition.error = action.payload;
  },
});

var updateRequisition = createAsyncAction<
  {
    testCodeFound: boolean;
    result: boolean;
    updatedRequisition: RequisitionData;
  }, //return type
  {
    id: number;
    fieldName: string;
    fieldValue: string;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "updateRequisition",
  url: baseURL + "/UpdateRequisition",
  pending: (state, action) => {
    state.updateRequisition.isLoading = true;
    if (action.params.fieldName === "testCodes")
      state.isUpdatingTestCodeList = true;
  },
  fulfilled: (state, action) => {
    state.updateRequisition.isLoading = false;
    if (action.params.fieldName === "testCodes")
      state.isUpdatingTestCodeList = false;
      
    const tempList = state.allRequisitionList.data;
    const tempRD = action.payload.updatedRequisition;
    const newRequisitionList =
      tempList && tempRD
        ? tempList.map((c) => {
            if (c.phn === tempRD.phn) return action.payload.updatedRequisition;
            else return c;
          })
        : [];
    state.allRequisitionList.data = newRequisitionList;
  },
  rejected: (state, action) => {
    state.updateRequisition.isLoading = false;
    state.updateRequisition.error = action.payload;
  },
  getAuthBearerToken
});

var resetRequisition = createAsyncAction<
  {
    result: boolean;
    resetRequisition: RequisitionData;
  }, //return type
  {
    id: number;
  }, //params
  State
>({
  actionPrefix: controlName,
  actionName: "resetRequisition",
  url: baseURL + "/ResetRequisition",
  pending: (state, action) => {
    state.resetRequisition.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.resetRequisition.isLoading = false;
    const tempList = state.allRequisitionList.data;
    const tempRD = action.payload.resetRequisition;
    const newRequisitionList =
      tempList && tempRD
        ? tempList.map((c) => {
            if (c.phn === tempRD.phn) return action.payload.resetRequisition;
            else return c;
          })
        : [];
    state.allRequisitionList.data = newRequisitionList;
  },
  rejected: (state, action) => {
    state.resetRequisition.isLoading = false;
    state.resetRequisition.error = action.payload;
  },
  getAuthBearerToken
});

var getAllRequisitions = createAsyncAction<
  RequisitionData[], //return type
  {}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getAllRequisitions",
  url: baseURL + "/GetAllRequisitions",
  pending: (state, action) => {
    state.allRequisitionList = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.allRequisitionList.isLoading = false;
    state.allRequisitionList.data = action.payload;
  },
  rejected: (state, action) => {
    state.allRequisitionList.isLoading = false;
    state.allRequisitionList.error = action.payload;
  },
  getAuthBearerToken
});

type State = {
  practiceRequisitionList: ReducerState<RequisitionData[]>;
  requisitionPHNAndNameList: ReducerState<ReqNameAndPhnItem[]>;
  getRequisition: ReducerState<RequisitionData>;
  addRequisition: ReducerState<RequisitionData>;
  allRequisitionList: ReducerState<RequisitionData[]>;
  // deleteRequisition: ReducerState<{
  //   result: boolean;
  //   newList: RequisitionData[];
  // }>;
  deleteRequisition: ReducerState<RequisitionData>;
  updateRequisition: ReducerState<{
    testCodeFound: boolean;
    result: boolean;
    updatedRequisition: RequisitionData;
  }>;
  resetRequisition: ReducerState<{
    result: boolean;
    resetRequisition: RequisitionData;
  }>;
  isUpdatingTestCodeList?: boolean;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    getRequisition: { isLoading: false },
    requisitionPHNAndNameList: { isLoading: false },
    addRequisition: { isLoading: false },
    allRequisitionList: { isLoading: false },
    deleteRequisition: { isLoading: false },
    practiceRequisitionList: { isLoading: false },
    updateRequisition: { isLoading: false },
    resetRequisition: { isLoading: false },
  } as State,
  reducers: {},
  extraReducers: {
    ...getRequisition.reducer,
    ...addRequisition.reducer,
    ...getPracticeRequisitions.reducer,
    ...getAllRequisitions.reducer,
    ...deleteRequisition.reducer,
    ...updateRequisition.reducer,
    ...resetRequisition.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getRequisition: getRequisition.action,
      addRequisition: addRequisition.action,
      deleteRequisition: deleteRequisition.action,
      getPracticeRequisitions: getPracticeRequisitions.action,
      getAllRequisitions: getAllRequisitions.action,
      updateRequisition: updateRequisition.action,
      resetRequisition: resetRequisition.action,
    },
  },
};

export default ret;
