import manageRequisition from "./manageRequisition/manageRequisition.duck";
import practiceMode from "./practiceMode/practiceMode.duck";
import settings from "./settings/settings.duck";
import markingList from "./markingList/markingList.duck";
import testMode from "pages/testMode/testMode.duck";

const ret = {
  reducer: {
    ...manageRequisition.reducer,
    ...practiceMode.reducer,
    ...settings.reducer,
    ...markingList.reducer,
    ...testMode.reducer,
  },
  actions: {
    ...manageRequisition.actions,
    ...practiceMode.actions,
    ...settings.actions,
    ...markingList.actions,
    ...testMode.actions,
  },
};

export default ret;
