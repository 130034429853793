import { getAuthBearerToken } from "@nait-aits/redux";

//You will need to setup these files as per MSAL instructions
import { msalInstance } from "index";
import { loginRequest } from "authConfig";

const getToken = () => {
  return getAuthBearerToken(msalInstance, loginRequest);
};

export default getToken;

export { getToken as getAuthBearerToken };
