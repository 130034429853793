import { Box, Button, CircularProgress, List, TextField, Typography } from "@material-ui/core";
import { AdminUser } from "common/components/types";
import { useState } from "react";
import styled from "styled-components";
import UserListItem from "./UserListItem";

export type Props = {
  adminUsers?: AdminUser[];
  isAddingUser: boolean;
  isLoadingUsers: boolean;
  onAddUser: (email: string, onComplete: () => void) => void;
  onRemoveUser: (id: string) => void;
};

export type State = {
  newUserEmail: string;
};

const AccessSettings = (props: Props) => {
  const [state, setState] = useState<State>({
    newUserEmail: "",
  });

  return (
    <div>
      <StyledTypography>
        The following users have Instructor/ Admin access to the application.
      </StyledTypography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          label="Email address"
          variant="filled"
          disabled={props.isAddingUser}
          style={{ width: 300, marginBottom: 10 }}
          value={state.newUserEmail}
          onChange={(e) => setState({ ...state, newUserEmail: e.target.value })}
        />
        <Box style={{ marginTop: -5 }}>
          <Button
            style={{ marginLeft: 10, marginRight: 10 }}
            variant="contained"
            color="primary"
            onClick={() => {
              props.onAddUser(state.newUserEmail, () => {
                setState({ ...state, newUserEmail: "" });
              });
            }}
          >
            Add
          </Button>
        </Box>
      </div>
      {props.isLoadingUsers && <CircularProgress />}
      {!props.isLoadingUsers && (
        <List style={{ overflow: "auto", width: 300 }}>
          {props.adminUsers &&
            props.adminUsers.map((c, index) => (
              <UserListItem
                key={index}
                adminUser={c}
                onDelete={() => {
                  props.onRemoveUser(c.id);
                }}
              />
            ))}
        </List>
      )}
    </div>
  );
};

export default AccessSettings;

const StyledTypography = styled(Typography)`
  margin: 30px 0px 10px 0px;
`;
