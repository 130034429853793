export type Props = {};
//MK: what does this page do?
//DA & BG: This is default redirect page displayed after initial Login redirect
const LoggedIn = (props: Props) => {
  return (
    <>
      <div
        style={{ width: "100%", height: "100vh", backgroundColor: "#002d74" }}
      >
        {" "}
      </div>
    </>
  );
};

export default LoggedIn;
