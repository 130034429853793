import styled from "styled-components";

export default function RequisitionDataEditorSkeleton() {
  return <Skeleton />;
}
const Skeleton = styled.div`
  background-color: #ebebeb;
  border-radius: 20px 20px 20px 20px;
  height: 900px;
  margin-left: 40px;
  width: 815px;
`;
