import { useEffect, useState } from "react";
import { useReduxState, useActions } from "store";
import RequistionDataList from "common/components/RequisitionDataList";
import RequistionDataEditor from "common/components/RequisitionDataEditor";
import { defaultRD } from "common/components/helpers";
import { openConfirm } from "common/dialogs/ConfirmBox";
import MainTemplate from "common/components/MainTemplate";
import ManageReqAppBarButtons from "./components/ManageReqAppBarButtons";
import RequisitionDataEditorSkeleton from "common/components/RequisitionDataEditorSkeleton";
import { StyledGrid } from "common/components/sharedStyledComponents";

type State = {
  showEditor: boolean;
  selectedReqId?: number;
};

function ManageRequisition() {
  const instructorRequisitionState = useReduxState((e) => e.manageRequisition);
  const instructorRequisitionActions = useActions().manageRequisition;
  const settingsActions = useActions().settings;
  const testCodeListDB = useReduxState((e) => e.settings).testCodeList.data;

  useEffect(() => {
    instructorRequisitionActions.getAllRequisitions({});
    settingsActions.getTestCodes({});
  }, []);
  const allReqList = instructorRequisitionState.allRequisitionList.data;

  const [state, setState] = useState<State>({
    showEditor: false,
  });

  useEffect(() => {
    var newReq = instructorRequisitionState.addRequisition.data;
    if (newReq?.id && newReq.id > 0) {
      setState({
        ...state,
        selectedReqId: newReq.id,
        showEditor: true,
      });
    }
  }, [instructorRequisitionState.addRequisition.data]);

  const selectedRequisition = allReqList?.filter(
    (c) => c.id === state.selectedReqId
  )[0];

  return (
    <MainTemplate
      title="Manage Requisition"
      requiresAdminAccess={true}
      appBarRight={
        <ManageReqAppBarButtons
          enableDownloadReqButton={
            selectedRequisition !== undefined && selectedRequisition.phn !== ""
          }
          selectedRequisition={
            selectedRequisition ? selectedRequisition : defaultRD
          }
        />
      }
    >
      <StyledGrid>
        <RequistionDataList
          type={"manage"}
          onAdd={() => {
            instructorRequisitionActions.addRequisition({
              newRequisition: defaultRD,
            });
          }}
          requisitionList={allReqList ? allReqList : []}
          onLabelSelected={(data) => {
            setState({ ...state, selectedReqId: data.id, showEditor: true });
          }}
          selectedReqId={state.selectedReqId}
        />
        {!state.showEditor && <RequisitionDataEditorSkeleton />}
        {state.showEditor && (
          <div>
            <RequistionDataEditor
              testCodeListDB={testCodeListDB ?? []}
              isManageRequisition={true}
              requisitionData={
                selectedRequisition ? selectedRequisition : defaultRD
              }
              onReset={() => {
                openConfirm({
                  title: "Reset Requisition",
                  description: `All the data in the fields will be cleared and you won't be able to get the data back. Continue?`,
                  callback: () => {
                    instructorRequisitionActions.resetRequisition({
                      id: selectedRequisition?.id ? selectedRequisition.id : 0,
                    });
                  },
                });
              }}
              onDelete={() => {
                openConfirm({
                  title: "Delete Requisition",
                  description: `PHN: ${selectedRequisition?.phn}. Continue?`,
                  callback: () => {
                    instructorRequisitionActions.deleteRequisition({
                      id: selectedRequisition?.id ? selectedRequisition.id : 0,
                    });
                    setState({
                      ...state,
                      showEditor: false,
                      selectedReqId: 0, //Assigning random negative index to not highlight the label after performing this action
                    });
                  },
                });
              }}
              onCopy={() => {
                selectedRequisition &&
                  instructorRequisitionActions.addRequisition({
                    newRequisition: selectedRequisition,
                  });
              }}
              onFormDataChange={(data, fieldName, fieldValue) => {
                instructorRequisitionActions.updateRequisition({
                  id: data.id ? data.id : 0,
                  fieldName: fieldName ? fieldName : "",
                  fieldValue: fieldValue ? fieldValue : "",
                });
              }}
              isUpdatingTestCodeList={
                instructorRequisitionState.isUpdatingTestCodeList
              }
            />
          </div>
        )}
      </StyledGrid>
    </MainTemplate>
  );
}
export default ManageRequisition;

// const StyledGrid = styled(Grid)`
//   margin: 100px auto;
//   justify-content: center;
//   align-items: flex-start;
//   display: flex;
//   flex-wrap: wrap;
//   box-sizing: border-box;
//   width: 1315px;
// `;
