import { Paper } from "@material-ui/core";
import RowItem from "common/components/RequisitionDataEditor/RowItem";
import {
  CodeContatiner,
  FieldsContainer,
  StyledBarCodeDiv,
  StyledLabelDiv,
  StyledRowDiv,
} from "common/components/sharedStyledComponents";
import {
  BracketNotation,
  RequisitionData,
  TestCode,
} from "common/components/types";
import ToggleSwitch from "common/components/RequisitionDataEditor/ToggleSwitch";
import TestCodes from "common/components/TestCodes";
import PatientCareBarcode from "common/components/PatientCareBarcode";
import EditorMenu from "common/components/RequisitionDataEditor/EditorMenu";
import styled from "styled-components";

export type Props = {
  isManageRequisition: boolean;
  requisitionData: BracketNotation & RequisitionData;
  onFormDataChange: (
    data: BracketNotation & RequisitionData,
    fieldName?: string,
    fieldValue?: string
  ) => void;
  onReset(): void;
  onDelete(): void;
  onCopy(): void;
  testCodeListDB: TestCode[];
  isUpdatingTestCodeList?: boolean;
};

export type RowData = {
  manageReqOnly?: boolean;
  placeholder: string;
  dataField: string;
};

export type ToggleData = {
  manageReqOnly?: boolean;
  label: string;
  dataField: string;
};

const toggleDatas: ToggleData[] = [
  {
    label: "Hide from Students?",
    manageReqOnly: true,
    dataField: "hideFromStudents",
  },
  {
    label: "Practice Requisition?",
    manageReqOnly: true,
    dataField: "practiceRequisition",
  },
];

const rowDatas: RowData[] = [
  {
    placeholder: "Requisition Meta Info:",
    manageReqOnly: true,
    dataField: "requisitionMetaInfo",
  },
  {
    placeholder: "PHN / Health Care Number:",
    dataField: "phn",
  },
  {
    placeholder: "Last Name:",
    dataField: "lastName",
  },
  {
    placeholder: "First Name:",
    dataField: "firstName",
  },
  {
    placeholder: "Sex:",
    dataField: "sex",
  },
  {
    placeholder: "Date Of Birth: DD/MM/YYYY",
    dataField: "dateOfBirth",
  },
  {
    placeholder: "Postal Code:",
    dataField: "postalCode",
  },
  {
    placeholder: "Ordering Physician:",
    dataField: "orderingPhysician",
  },
  {
    placeholder: "Physician Code:",
    dataField: "physicianCode",
  },
  {
    placeholder: "Collection Date: DD/MM/YYYY",
    dataField: "collectionDate",
  },
  {
    placeholder: "Collection Time: 24H",
    dataField: "collectionTime",
  },
  {
    placeholder: "Collection Location:",
    dataField: "collectionLocation",
  },
  {
    placeholder: "To Collect:",
    dataField: "toCollect",
  },
];

const RequistionDataEditor = (props: Props) => {
  const {
    isManageRequisition,
    requisitionData,
    onFormDataChange,
    onReset,
    onDelete,
    onCopy,
    testCodeListDB,
    isUpdatingTestCodeList,
  } = props;

  return (
    <OuterPaper>
      <StyledTopDiv>
        <div style={{ flexGrow: 1 }}>
          <StyledBarCodeDiv>
            <PatientCareBarcode
              value={requisitionData.accessionNumber}
              requisitionData={requisitionData}
            />
          </StyledBarCodeDiv>
        </div>
        <div style={{ marginTop: 30 }}>
          <EditorMenu onReset={onReset} onCopy={onCopy} onDelete={onDelete} />
        </div>
      </StyledTopDiv>
      <Paper
        style={{
          display: "flex",
          borderRadius: "0px 0px 20px 20px",
        }}
      >
        <FieldsContainer>
          {rowDatas.map((c, index) => {
            if (!isManageRequisition && c.manageReqOnly)
              return <div key={index}></div>;
            else
              return (
                <RowItem
                  key={index}
                  placeholder={c.placeholder}
                  disabled={isManageRequisition && c.dataField === "phn"}
                  value={
                    requisitionData[c.dataField]
                      ? requisitionData[c.dataField]
                      : ""
                  }
                  onBlur={(value) => {
                    onFormDataChange(
                      {
                        ...requisitionData,
                        [c.dataField]: value,
                      },
                      c.dataField,
                      value
                    );
                  }}
                />
              );
          })}

          {toggleDatas.map((c, index) => {
            if (!isManageRequisition && c.manageReqOnly)
              return <div key={index}></div>;
            else
              return (
                <StyledRowDiv key={index}>
                  <StyledLabelDiv>{c.label}</StyledLabelDiv>
                  <ToggleSwitch
                    labelText={requisitionData[c.dataField] ? "Yes" : "No"}
                    labelColor={requisitionData[c.dataField] ? "Green" : "Red"}
                    toggleState={
                      requisitionData[c.dataField]
                        ? requisitionData[c.dataField]
                        : false
                    }
                    onToggle={(toggleState) => {
                      toggleState
                        ? onFormDataChange(
                            {
                              ...requisitionData,
                              [c.dataField]: false,
                            },
                            c.dataField,
                            "false"
                          )
                        : onFormDataChange(
                            {
                              ...requisitionData,
                              [c.dataField]: true,
                            },
                            c.dataField,
                            "true"
                          );
                    }}
                  />
                </StyledRowDiv>
              );
          })}
        </FieldsContainer>
        <CodeContatiner>
          <StyledDiv>CODES</StyledDiv>
          <TestCodes
            testCodeListDB={testCodeListDB}
            isUpdatingTestCodeList={isUpdatingTestCodeList}
            validateTestCode={isManageRequisition}
            testCodeList={
              requisitionData.testCodes ? requisitionData.testCodes : []
            }
            onTestCodeListUpdate={(updatedTestCodeList) => {
              onFormDataChange(
                {
                  ...requisitionData,
                  testCodes: updatedTestCodeList,
                },
                "testCodes",
                JSON.stringify(updatedTestCodeList)
              );
            }}
          />
        </CodeContatiner>
      </Paper>
    </OuterPaper>
  );
};

export default RequistionDataEditor;

const OuterPaper = styled(Paper)`
  margin-left: 40px;
  border-radius: 20px 20px 20px 20px;
  background-color: #ebebeb;
`;

const StyledTopDiv = styled.div`
  background-color: #ebebeb;
  border-radius: 20px 20px 20px 20px;
  display: flex;
`;

const StyledDiv = styled.div`
  color: #858585;
  font-weight: bold;
  margin-bottom: 15px;
`;
