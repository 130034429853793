import pages from "pages/reducer";
import components from "common/reducer";
//import components from "common/components/reducer";

const reducers = {
  ...pages.reducer,
  ...components.reducer
};

const actions = {
  ...pages.actions,
  ...components.actions
};

export type ActionState = typeof actions;

const index = {
  reducers,
  actions,
};

export default index;
