import { createSlice } from "@reduxjs/toolkit";
import { AdminUser, TestCode } from "common/components/types";
import {
  createAsyncAction,
  ReducerState,
} from "@nait-aits/redux";
import getAuthBearerToken from "store/getAuthBearerToken";

const controlName = "settings";
const baseUrl = `${process.env.REACT_APP_API_BASE}/settings`;

type State = {
  adminUserList: ReducerState<AdminUser[]>;
  addAdminUser: ReducerState<AdminUser>;
  removeAdminUser: ReducerState<AdminUser>;
  testCodeList: ReducerState<TestCode[]>;
  addTestCode: ReducerState<TestCode>;
  updateTestCode: ReducerState<TestCode>;
  removeTestCode: ReducerState<TestCode>;
};

var getAdminUsers = createAsyncAction<AdminUser[], {}, State>({
  actionPrefix: controlName,
  actionName: "getAdminUsers",
  url: baseUrl + "/GetAdminUsers",
  pending: (state, action) => {
    state.adminUserList.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.adminUserList.isLoading = false;
    state.adminUserList.data = action.payload;
  },
  rejected: (state, action) => {
    state.adminUserList.isLoading = false;
    state.adminUserList.error = action.payload;
  },
  getAuthBearerToken
});

var addAdminUser = createAsyncAction<
  AdminUser,
  { email: string; onComplete: () => void },
  State
>({
  actionPrefix: controlName,
  actionName: "addAdminUser",
  url: baseUrl + "/AddAdminUser",
  pending: (state, action) => {
    state.addAdminUser.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.addAdminUser.isLoading = false;
    state.addAdminUser.data = action.payload;
    state.adminUserList.data?.push(action.payload);
  },
  rejected: (state, action) => {},
  onComplete: (payload) => {
    payload.params.onComplete();
  },
  getAuthBearerToken
});

var removeAdminUser = createAsyncAction<AdminUser, { id: string }, State>({
  actionPrefix: controlName,
  actionName: "removeAdminUser",
  url: baseUrl + "/RemoveAdminUser",
  pending: (state, action) => {
    state.removeAdminUser.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.removeAdminUser.isLoading = true;
    state.removeAdminUser.data = action.payload;
    state.adminUserList.data = state.adminUserList.data?.filter(
      (c) => c.id !== action.payload.id
    );
  },
  rejected: (state, action) => {
    state.removeAdminUser.isLoading = false;
    state.removeAdminUser.error = action.payload;
  },
  getAuthBearerToken
});

var getTestCodes = createAsyncAction<TestCode[], {}, State>({
  actionPrefix: controlName,
  actionName: "getTestCodes",
  url: baseUrl + "/GetAllTestCodes",
  pending: (state, action) => {
    state.testCodeList.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.testCodeList.isLoading = true;
    state.testCodeList.data = action.payload;
  },
  rejected: (state, action) => {},
  getAuthBearerToken
});

var addTestCode = createAsyncAction<
  TestCode,
  { code: string; description: string; category: string, onComplete: () => void },
  State
>({
  actionPrefix: controlName,
  actionName: "addTestCode",
  url: baseUrl + "/AddTestCode",
  pending: (state, action) => {
    state.addTestCode.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.addTestCode.isLoading = false;
    state.addTestCode.data = action.payload;
    state.testCodeList.data?.push(action.payload);
    state.testCodeList.data = state.testCodeList.data?.sort((a,b) => a.code > b.code ? 1 : 0);
  },
  rejected: (state, action) => {},
  onComplete: (payload) => {
    payload.params.onComplete();
  },
  getAuthBearerToken
});

var updateTestCode = createAsyncAction<
  TestCode,
  { id:number, code: string; description: string; category: string, onComplete: () => void },
  State
>({
  actionPrefix: controlName,
  actionName: "updateTestCode",
  url: baseUrl + "/UpdateTestCode",
  pending: (state, action) => {
    state.updateTestCode.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.updateTestCode.isLoading = false;
    state.updateTestCode.data = action.payload;
    state.testCodeList.data = state.testCodeList.data?.map(c => {
      if (c.id === action.payload.id) return action.payload;
      return c;
    });
  },
  rejected: (state, action) => {},
  onComplete: (payload) => {
    payload.params.onComplete();
  },
  getAuthBearerToken
});

var removeTestCode = createAsyncAction<TestCode, { id: number }, State>({
  actionPrefix: controlName,
  actionName: "removeTestCode",
  url: baseUrl + "/removeTestCode",
  pending: (state, action) => {
    state.removeAdminUser.isLoading = true;
  },
  fulfilled: (state, action) => {
    state.removeTestCode.isLoading = false;
    state.removeTestCode.data = action.payload;
    state.testCodeList.data = state.testCodeList.data?.filter(
      (c) => c.id !== action.payload.id
    );
  },
  rejected: (state, action) => {},
  getAuthBearerToken
});

var slice = createSlice({
  name: controlName,
  initialState: {
    adminUserList: { isLoading: false, data: [] },
    addAdminUser: { isLoading: false },
    removeAdminUser: { isLoading: false },
    testCodeList: { isLoading: false },
    addTestCode: { isLoading: false },
    updateTestCode: { isLoading: false },
    removeTestCode: { isLoading: false },
  } as State,
  reducers: {},
  extraReducers: {
    ...getAdminUsers.reducer,
    ...addAdminUser.reducer,
    ...removeAdminUser.reducer,
    ...getTestCodes.reducer,
    ...addTestCode.reducer,
    ...removeTestCode.reducer,
    ...updateTestCode.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getAdminUsers: getAdminUsers.action,
      addAdminUser: addAdminUser.action,
      removeAdminUser: removeAdminUser.action,
      getTestCodes: getTestCodes.action,
      addTestCode: addTestCode.action,
      removeTestCode: removeTestCode.action,
      updateTestCode: updateTestCode.action,
    },
  },
};

export default ret;