import { StyledTypography } from "common/components/sharedStyledComponents";
import styled from "styled-components";

type ReviewLabelSkeletonProps = {
  isStudentRequisitionListExists: boolean;
};

export default function ReviewLabelSkeleton(props: ReviewLabelSkeletonProps) {
  return (
    <Skeleton>
      <StyledTypography variant="h5">
        {props.isStudentRequisitionListExists
          ? "Select a patient name from the left to begin"
          : "No Patient Label found. Please click Back button, create labels and try again"}
      </StyledTypography>
    </Skeleton>
  );
}
const Skeleton = styled.div`
  margin-top: 20px;
  width: 780px;
`;
