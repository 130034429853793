import Divider from "@material-ui/core/Divider";
import styled from "styled-components";
import { RequisitionData } from "common/components/types";
import LookupLabel from "pages/Review/components/Discrepancies/LookupLabel";
import { DiscrepancyList } from "pages/Review/components/Discrepancies/DiscrepancyList";

type Props = {
  studentRequisitionData: RequisitionData;
  serverRequisitionData: RequisitionData;
};

const Discrepancies = (props: Props) => {
  const diff = DiscrepancyList(
    props.studentRequisitionData,
    props.serverRequisitionData
  );

  return (
    <div>
      <StyledLabelDiv>DISCREPANCIES:</StyledLabelDiv>
      <Divider color={"gray"} />
      <StyledDiscrepanciesDiv>
        {diff.length === 0 ? (
          <ul>
            <li>No Discrepancies</li>
          </ul>
        ) : (
          <ul>
            {diff.map((c, index) => {
              return <li key={index}>{LookupLabel(c)} </li>;
            })}
          </ul>
        )}
      </StyledDiscrepanciesDiv>
    </div>
  );
};

export default Discrepancies;

const StyledLabelDiv = styled.div`
  color: #858585;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 30px;
`;

const StyledDiscrepanciesDiv = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
`;
