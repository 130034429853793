import styled from "styled-components";
import React from "react";
import { useBarcode } from "react-barcodes";
import { RequisitionData } from "common/components/types";

export type Props = {
  value?: string;
  studentRequisitionData: RequisitionData;
  serverRequisitionData?: RequisitionData;
  discrepancyList: string[];
  className?: string;
};

const SidePatientData: React.FC<Props> = ({
  value,
  studentRequisitionData,
  className,
  discrepancyList,
}) => {
  function retValue(key: string, value: string | undefined) {
    return value ? value : discrepancyList.includes(key) ? "—" : "";
  }
  return (
    <div className={className}>
      <div>{value}</div>
      <div>
        <StyledLabel isDiff={discrepancyList.includes("lastName")}>
          {retValue("lastName", studentRequisitionData.lastName?.toUpperCase())}
        </StyledLabel>{" "}
        <StyledLabel isDiff={discrepancyList.includes("firstName")}>
          {retValue("firstName", studentRequisitionData.firstName)}
        </StyledLabel>
      </div>
      <div>
        <StyledLabel isDiff={discrepancyList.includes("phn")}>
          {studentRequisitionData.phn}
        </StyledLabel>{" "}
        <StyledLabel isDiff={discrepancyList.includes("sex")}>
          {retValue("sex", studentRequisitionData.sex)}
        </StyledLabel>
      </div>
      <div>
        <StyledLabel isDiff={discrepancyList.includes("collectionDate")}>
          {retValue("collectionDate", studentRequisitionData.collectionDate)}
        </StyledLabel>{" "}
        <StyledLabel isDiff={discrepancyList.includes("collectionTime")}>
          {retValue("collectionTime", studentRequisitionData.collectionTime)}
        </StyledLabel>
      </div>
    </div>
  );
};

const ReviewStudentBarcode: React.FC<Props> = ({
  studentRequisitionData,
  serverRequisitionData,
  value,
  discrepancyList,
}) => {
  const defaultVal = "0000000000";
  const { inputRef } = useBarcode({
    value: value ? value : defaultVal,
    options: {
      displayValue: false,
      background: "#ffffff",
      height: 50,
    },
  });

  const studentCodes: string[] = studentRequisitionData.testCodes
    ? studentRequisitionData.testCodes?.map((c) => c.code)
    : [];
  const serverCodes: string[] = serverRequisitionData?.testCodes
    ? serverRequisitionData?.testCodes?.map((c) => c.code)
    : [];

  const correctCodes: string[] = serverCodes.filter(function (c) {
    return studentCodes.includes(c);
  });

  const missingCodes: string[] = serverCodes.filter(function (c) {
    return !studentCodes.includes(c);
  });

  const extraCodes: string[] = studentCodes.filter(function (c) {
    return !serverCodes.includes(c);
  });

  function retValue(key: string, value: string | undefined) {
    return value ? value : discrepancyList.includes(key) ? "—" : "";
  }

  return (
    <div>
      <BarcodeContainer>
        <StyledBarcodeNumber>{value || defaultVal}</StyledBarcodeNumber>
        <div style={{ padding: 5 }}>
          <div style={{ marginLeft: 10 }}>
            <div style={{ fontSize: 20 }}>
              <StyledLabel isDiff={discrepancyList.includes("lastName")}>
                {retValue(
                  "lastName",
                  studentRequisitionData.lastName?.toUpperCase()
                )}
              </StyledLabel>{" "}
              <StyledLabel isDiff={discrepancyList.includes("firstName")}>
                {retValue("firstName", studentRequisitionData.firstName)}
              </StyledLabel>
            </div>
            <div style={{ wordWrap: "break-word" }}>
              <StyledLabel isDiff={discrepancyList.includes("phn")}>
                {studentRequisitionData.phn}
              </StyledLabel>{" "}
              <StyledLabel isDiff={discrepancyList.includes("sex")}>
                {retValue("sex", studentRequisitionData.sex)}
              </StyledLabel>{" "}
              <StyledLabel isDiff={discrepancyList.includes("dateOfBirth")}>
                {retValue("dateOfBirth", studentRequisitionData.dateOfBirth)}
              </StyledLabel>
            </div>
            <div>
              <StyledLabel
                isDiff={discrepancyList.includes("collectionLocation")}
              >
                {retValue(
                  "collectionLocation",
                  studentRequisitionData.collectionLocation
                ).toUpperCase()}
              </StyledLabel>
            </div>
            <div>
              <StyledLabel isDiff={discrepancyList.includes("collectionDate")}>
                {retValue(
                  "collectionDate",
                  studentRequisitionData.collectionDate
                )}
              </StyledLabel>{" "}
              <StyledLabel isDiff={discrepancyList.includes("collectionTime")}>
                {retValue(
                  "collectionTime",
                  studentRequisitionData.collectionTime
                )}
              </StyledLabel>
            </div>
          </div>
          <svg ref={inputRef} />
          <div style={{ marginLeft: 10 }}>
            {correctCodes.length > 0 && (
              <StyledTestCodeLabel isDiff={false}>
                {correctCodes.map((c) => c).join(" ")}
              </StyledTestCodeLabel>
            )}
            {missingCodes.length > 0 && (
              <StyledTestCodeLabel isDiff={true}>
                {missingCodes.map((c) => c).join(" ")}
              </StyledTestCodeLabel>
            )}
            {extraCodes.length > 0 && (
              <StyledTestCodeLabel isDiff={true}>
                {extraCodes.map((c) => c).join(" ")}
              </StyledTestCodeLabel>
            )}
          </div>
        </div>
        <div style={{ borderLeft: "dotted 1px gray", width: "100%" }}>
          <StyledSidePatientData
            value={value ? value : defaultVal}
            studentRequisitionData={studentRequisitionData}
            discrepancyList={discrepancyList}
          />
          <div style={{ borderBottom: "dotted 1px gray", width: "100%" }}></div>
          <StyledSidePatientData
            value={value ? value : defaultVal}
            studentRequisitionData={studentRequisitionData}
            discrepancyList={discrepancyList}
          />
        </div>
      </BarcodeContainer>
    </div>
  );
};

const BarcodeContainer = styled.div`
  border: dotted 1px gray;
  border-radius: 4px;
  display: flex;
  font-family: "Roboto Mono", monospace;
  font-size: 12px;
  margin-top: 10px;
  width: 360px;
  word-wrap: break-word;
`;

const StyledSidePatientData = styled(SidePatientData)`
  margin: 5px;
  width: 70px;
`;

const StyledBarcodeNumber = styled.div`
  writing-mode: vertical-rl;
  text-orientation: sideways;
  transform: rotate(180deg);
  margin-top: auto;
  margin-bottom: auto;
  font-size: 18px;
  padding: 5px;
`;

const StyledLabel = styled.label<{ isDiff: boolean }>`
  color: ${(props) => (props.isDiff ? "red" : "black")} !important;
  /* margin-right: 10px; */
`;

const StyledTestCodeLabel = styled.label<{ isDiff: boolean }>`
  color: ${(props) => (props.isDiff ? "red" : "black")} !important;
  margin-right: 10px;
`;

export default ReviewStudentBarcode;
