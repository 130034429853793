import useMediaQuery from "@material-ui/core/useMediaQuery";
import { RequisitionData } from "common/components/types";

export const viewSize = {
  smallDesktop: "(max-width: 1500px)",
  mobile: "(max-width: 870px)",
};

export const useViewSize = () => ({
  isSmallDesktop: useMediaQuery(viewSize.smallDesktop),
  isMobile: useMediaQuery(viewSize.mobile),
});

export const defaultRD: RequisitionData = {
  requisitionMetaInfo: "",
  phn: "",
  lastName: "",
  firstName: "",
  sex: "",
  dateOfBirth: "",
  postalCode: "",
  orderingPhysician: "",
  physicianCode: "",
  collectionDate: "",
  collectionTime: "",
  collectionLocation: "",
  toCollect: "",
  accessionNumber: "0000000000",
  testCodes: [],
  hideFromStudents: true,
  practiceRequisition: true,
};


export const randomNegativeIndex = () => {
  return Math.floor(100 + Math.random() * 90000) * -1
};

export const randomNumber = () => {
  return Math.floor(1000000000 + Math.random() * 9000000000).toString();
};