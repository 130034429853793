import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { AdminUser } from "common/components/types";
import { openConfirm } from "common/dialogs/ConfirmBox";

type Props = {
  adminUser: AdminUser;
  onDelete: (code: string) => void;
};

const UserListItem = (props: Props) => {
  return (
    <div>
      <ListItem
        style={{ border: "1px solid #E5E5E5", marginTop: 10, marginBottom: 10 }}
      >
        <ListItemText primary={props.adminUser.userName} />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            color="primary"
            onClick={(e: any) => {
              openConfirm({
                title: "Remove User",
                description: `Remove ${props.adminUser.userName} as an Admin?`,
                callback: () => {
                  props.onDelete(props.adminUser.id);
                },
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
};
export default UserListItem;
