import { Typography } from "@material-ui/core";
import MainTemplate from "common/components/MainTemplate";

export type Props = {};

const AccessDenied = (props: Props) => {
  return (
    <MainTemplate title="Access Denied">
      <Typography style={{ margin: 20 }} variant={"h5"}>
        Access Denied!
      </Typography>
    </MainTemplate>
  );
};

export default AccessDenied;
