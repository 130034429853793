import { StyledTypography } from "common/components/sharedStyledComponents";
import styled from "styled-components";
export default function NoMarkingListSkeleton() {
  return (
    <Skeleton>
      <StyledTypography variant="h5">
        No marking list found. Please click Back button, create marking list and
        try again
      </StyledTypography>
    </Skeleton>
  );
}
const Skeleton = styled.div`
  margin-top: 20px;
`;
