import React, { useState } from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { IconButton } from "@material-ui/core";
import HoverMenu from "./HoverMenu";
import styled from "styled-components";

export type Props = {
  isAuthenticated: boolean;
  onLogInClick(): void;
  onLogOffClick(): void;
};

const AccountButton = ({
  onLogInClick,
  onLogOffClick,
}: // isAuthenticated,
Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  // if (isAuthenticated) {
  return (
    <div>
      <IconButton onClick={() => setMenuOpen(true)}>
        <WhiteAccountCircleIcon />
      </IconButton>
      <HoverMenu
        isOpen={isMenuOpen}
        setMenuClosed={() => setMenuOpen(false)}
        handleLogOff={onLogOffClick}
      />
    </div>
  );
  // }

  // return (
  //   <Button
  //     onClick={onLogInClick}
  //     variant="contained"
  //     color="primary"
  //     startIcon={<WhiteAccountCircleIcon />}
  //   >
  //     Login
  //   </Button>
  // );
};

export default AccountButton;

const WhiteAccountCircleIcon = styled(AccountCircleIcon)`
  font-size: 2rem;
`;
