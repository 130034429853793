import { RequisitionData } from "common/components/types";
import { useState } from "react";
import styled from "styled-components";
import PrintLabel from "pages/Finish/components/PrintLabel";
import PrintList from "pages/Finish/components/PrintList";
import { Tabs, Tab } from "@material-ui/core";
import MainTemplate from "common/components/MainTemplate";
import FinishAppBarButtons from "./components/FinishAppBarButtons";
import { openConfirm } from "common/dialogs/ConfirmBox";
import { useHistory } from "react-router";
import { TestModeRoute } from "common/components/paths";
import { useActions, useReduxState } from "store";

type State = {
  requisitionList: RequisitionData[];
  selectedTab: number;
};
const Finish = () => {
  const testRequisitionState = useReduxState((e) => e.testMode);
  const testRequisitionActions = useActions().testMode;

  const [state, setState] = useState<State>({
    requisitionList: testRequisitionState.requisitionList
      ? testRequisitionState.requisitionList
      : [],
    selectedTab: 0,
  });

  const history = useHistory();

  return (
    <MainTemplate
      title="Finish"
      appBarRight={
        <FinishAppBarButtons
          onNewSessionClick={() => {
            openConfirm({
              title: "Are you sure you want to start a New Session?",
              description:
                "All the patient labels you've made will be cleared and you won't be able to get them back.",
              okBtnText: "Start New Session",
              callback: () => {
                testRequisitionActions.clearAllTestRequisitions();
                history.push(TestModeRoute);
              },
            });
          }}
        />
      }
    >
      <Content>
        <Tabs
          value={state.selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => {
            setState({ ...state, selectedTab: v });
          }}
        >
          <Tab label="Labels" />
          <Tab label="List" />
        </Tabs>
      </Content>
      <div>
        {state.selectedTab === 0 && (
          <PrintLabel
            requisitionList={state.requisitionList}
            onEditClick={() => {
              testRequisitionActions.selectTestRequisition(0);
              history.push(TestModeRoute);
            }}
          />
        )}
        {state.selectedTab === 1 && (
          <PrintList
            requisitionList={state.requisitionList}
            onEditClick={() => {
              testRequisitionActions.selectTestRequisition(0);
              history.push(TestModeRoute);
            }}
          />
        )}
      </div>
    </MainTemplate>
  );
};

export default Finish;

const Content = styled.div`
  margin: auto;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
