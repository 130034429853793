import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncAction,
  ReducerState,
} from "@nait-aits/redux";
import { RequisitionData } from "common/components/types";
import getAuthBearerToken from "store/getAuthBearerToken";


const controlName = "markingList";

const baseURL = `${process.env.REACT_APP_API_BASE}/requisition`
// type Return = {};
// type Data = ReducerState<Return>;

  var getAllRequisitions = createAsyncAction<
  RequisitionData[], //return type
  {}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getAllRequisitions",
  url: baseURL+"/GetAllRequisitions",
  // url: "https://localhost:44383/requisition/GetAllRequisitions",
  pending: (state, action) => {
    state.allRequisitionList = {
      
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.allRequisitionList.isLoading = false;
    state.allRequisitionList.data = action.payload;
  },
  rejected: (state, action) => {
    state.allRequisitionList.isLoading = false;
    state.allRequisitionList.error = action.payload;
  },
  getAuthBearerToken
});

var updateSelectedReqIds : (
  state: State,
  action: PayloadAction<number[]>
) => void = (state, action)  => {
  state.selectedReqIds = action.payload;
};

type State = { 
  allRequisitionList: ReducerState<RequisitionData[]>;
  selectedReqIds:number[];
};

var slice = createSlice({
  name: controlName,
  initialState: { 
    allRequisitionList: { isLoading: false },
    selectedReqIds:[]
  } as State,
  reducers: {
     updateSelectedReqIds,
  },
  extraReducers: {    
    ...getAllRequisitions.reducer,    
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,      
      getAllRequisitions: getAllRequisitions.action,      
    },
  },
};

export default ret;