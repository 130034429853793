import { useMsal } from "@azure/msal-react";
import { Button, Typography } from "@material-ui/core";
import useOnClickOutside from "common/helpers/useOnClickOutside";
import React, { useRef } from "react";
import styled from "styled-components";

export type Props = {
    setMenuClosed():void;
    isOpen:boolean;
    handleLogOff():void;
}

export default function HoverMenu({setMenuClosed, handleLogOff, isOpen}:Props) {
  const ref = useRef(null);
  useOnClickOutside(ref, setMenuClosed);
  const { accounts } = useMsal();

  if(!isOpen)
  return null;

  return (
    <Container ref={ref} >
      <Typography style={{padding:'10px 0'}}>Welcome, {accounts[0].name} </Typography>
      <Button 
        color={"primary"}
        variant={"contained"}
        onClick={() => {
          handleLogOff();
          setMenuClosed();
          
      }}>Log Out</Button>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 62px;
  right: 8px;
  color: #000;
  padding:1em;
  width: 250px;
  background-color: #fff;
  border-radius: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
`;
