import styled from "styled-components";
import PrintSharpIcon from "@material-ui/icons/PrintSharp";
import { Button } from "@material-ui/core";
type MarkingListAppBarButtonsProps = {
  onPrintListClick: () => void;
};
export default function MarkingListAppBarButtons(
  props: MarkingListAppBarButtonsProps
) {
  return (
    <>
      <Button onClick={props.onPrintListClick}>
        <PrintSharpIcon style={{ marginRight: 10 }} />
        Print List
      </Button>
      <Spacer />
    </>
  );
}

const Spacer = styled.div`
  margin: 1em;
`;
