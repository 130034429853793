import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "authConfig";
import AccountButton from "./AccountButton";
import styled from "styled-components";

export function performLogin(
  instance: IPublicClientApplication,
  loginCallback?: () => void
) {
  instance
    .loginRedirect(loginRequest)
    .then((e) => {
      loginCallback && loginCallback();
    })
    .catch((e) => {
      console.log(e);
    });
}

export type Props = {};

const MSALAccountButton = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  return (
    <Container>
      <AccountButton
        isAuthenticated={isAuthenticated}
        onLogInClick={() => {
          performLogin(instance);
        }}
        onLogOffClick={() => {
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }}
      />
    </Container>
  );
};

export default MSALAccountButton;

const Container = styled.div`
  
  margin-right: 30px;
`;
