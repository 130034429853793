import styled from "styled-components";
import { Button } from "@material-ui/core";
type TestAppBarButtonsProps = {
  onFinishClick: () => void;
  enableFinishButton: boolean;
};
export default function TestAppBarButtons(props: TestAppBarButtonsProps) {
  return (
    <>
      <Button
        variant="contained"
        size="large"
        disabled={!props.enableFinishButton}
        onClick={props.onFinishClick}
      >
        Finish
      </Button>
      <Spacer />
    </>
  );
}

const Spacer = styled.div`
  margin: 1em;
`;
