import { IconButton, Toolbar } from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import styled from "styled-components";
import MSALAccountButton from "common/components/AccountButton/MSALAccountButton";
import { useViewSize } from "common/components/helpers";
import { ReactNode } from "react";

export type Props = {
  handleDrawerToggle(): void;
  title: string;
  right?: ReactNode;
};

const TopAppBar = (props: Props) => {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
  const { isSmallDesktop } = useViewSize();
  return (
    <div>
      {isSmallDesktop && (
        <StyledAppBar position="fixed" size={"small"} $scrolled={trigger}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <TitleSpan>{props.title}</TitleSpan>
            <Fill />
            {props.right}
            <MSALAccountButton />
          </Toolbar>
        </StyledAppBar>
      )}

      {!isSmallDesktop && (
        <StyledAppBar size={"large"} position={"fixed"} $scrolled={trigger}>
          <Toolbar>
            <TitleSpan>{props.title}</TitleSpan>
            <Fill />

            {props.right}
            <MSALAccountButton />
          </Toolbar>
        </StyledAppBar>
      )}
    </div>
  );
};

export default TopAppBar;

const Fill = styled.div`
  flex: 1;
`;

const StyledAppBar = styled(AppBar)<{
  size: "small" | "large";
  $scrolled: boolean;
}>`
  color: #0825ca;
  height: 70px;
  box-shadow: ${(props) =>
    props.$scrolled
      ? "0px 1px 2px 0px rgba(60,64,67,.3),0px 1px 3px 1px rgba(60,64,67,.15)"
      : "none"};
  background-color: ${(props) => (props.$scrolled ? "#fafafa" : "#fafafa")};
  ${(props) => props.size === "large" && "margin-left: 270px;"}
  ${(props) => props.size === "large" && "width: calc(100% - 270px);"}
`;

const TitleSpan = styled.span`
  font-size: 1.8em;
`;
