import { defaultRD } from "common/components/helpers";
import RequistionDataEditor from "common/components/RequisitionDataEditor";
import RequistionDataList from "common/components/RequisitionDataList";
import { useEffect, useState } from "react";
import { useActions, useReduxState } from "store";
import DownloadRequisitionsDialog from "common/components/DownloadRequisitionsDialog";
import { openConfirm } from "common/dialogs/ConfirmBox";
import MainTemplate from "common/components/MainTemplate";
import PracticeAppBarButtons from "./components/PracticeAppBarButtons";
import RequisitionDataEditorSkeleton from "common/components/RequisitionDataEditorSkeleton";
import { useHistory } from "react-router";
import { ReviewRoute } from "common/components/paths";
import { StyledGrid } from "common/components/sharedStyledComponents";

type State = {
  showEditor: boolean;
  openDownloadRequisition: boolean;
};

const PracticeMode = () => {
  const practiceRequisitionState = useReduxState((e) => e.practiceMode);
  const practiceRequisitionActions = useActions().practiceMode;
  const settingsActions = useActions().settings;
  const testCodeListDB = useReduxState((e) => e.settings).testCodeList.data;

  useEffect(() => {
    practiceRequisitionActions.getPracticeRequisitions({});
    settingsActions.getTestCodes({});
    if (practiceRequisitionState.selectedReqId > 0) {
      practiceRequisitionActions.selectPracticeRequisition(
        practiceRequisitionState.selectedReqId ?? 0
      );
      setState({
        ...state,
        showEditor: true,
      });
    }
  }, []);

  const serverReqList = practiceRequisitionState.practiceRequisitionList.data
    ? practiceRequisitionState.practiceRequisitionList.data
    : [];

  const [state, setState] = useState<State>({
    showEditor: false,
    openDownloadRequisition: false,
  });

  const selectedRequisition = practiceRequisitionState.requisitionList.filter(
    (c) => c.id === practiceRequisitionState.selectedReqId
  )[0];
  const history = useHistory();
  return (
    <MainTemplate
      title="Practice"
      appBarRight={
        <PracticeAppBarButtons
          onDownloadPracticeReqClick={() => {
            setState({ ...state, openDownloadRequisition: true });
          }}
          onReviewClick={() => {
            var validPHNs = serverReqList.map((c) => c.phn);
            var isAnyPHNNull =
              practiceRequisitionState.requisitionList.filter(
                (c) => c.phn === ""
              ).length > 0;
            var invalidPHNs = practiceRequisitionState.requisitionList
              .filter((c) => !validPHNs.includes(c.phn))
              .map((c) => c.phn);
            if (isAnyPHNNull) {
              openConfirm({
                title: "Invalid PHN",
                description: `One or more labels have NULL PHN value. Please check the labels and try again`,
                isAlert: true,
              });
            } else if (invalidPHNs.length > 0) {
              openConfirm({
                title: "Error",
                description: `The following PHNs are invalid: ${invalidPHNs.join(
                  ", "
                )}. Please enter valid PHNs.`,
                isAlert: true,
              });
            } else history.push(ReviewRoute);
          }}
          enableReviewButton={
            practiceRequisitionState.requisitionList.length > 0
          }
        />
      }
    >
      <StyledGrid>
        <RequistionDataList
          requisitionList={practiceRequisitionState.requisitionList}
          onAdd={() => {
            practiceRequisitionActions.addPracticeRequisition();
            setState({
              ...state,
              showEditor: true,
            });
          }}
          onLabelSelected={(data) => {
            setState({
              ...state,
              showEditor: true,
            });
            practiceRequisitionActions.selectPracticeRequisition(data.id ?? 0);
          }}
          onClearAll={() => {
            practiceRequisitionActions.clearAllPracticeRequisitions();
            setState({
              ...state,
              showEditor: false,
              openDownloadRequisition: false,
            });
          }}
          type="practice"
          selectedReqId={practiceRequisitionState.selectedReqId}
        />

        {!state.showEditor && <RequisitionDataEditorSkeleton />}

        {state.showEditor && (
          <RequistionDataEditor
            testCodeListDB={testCodeListDB ?? []}
            isManageRequisition={false}
            requisitionData={
              selectedRequisition ? selectedRequisition : defaultRD
            }
            onReset={() => {
              openConfirm({
                title: "Reset Requisition",
                description: `All the data in the fields will be cleared and you won't be able to get the data back. Continue?`,
                callback: () => {
                  practiceRequisitionActions.updatePracticeRequisition(
                    { ...defaultRD, accessionNumber: selectedRequisition.accessionNumber, id:selectedRequisition.id  }
                  );
                },
              });
            }}
            onDelete={() => {
              openConfirm({
                title: "Delete Requisition",
                description: `Accession #: ${selectedRequisition?.accessionNumber}. Continue?`,
                callback: () => {
                  practiceRequisitionActions.removePracticeRequisition(
                    practiceRequisitionState.selectedReqId
                  );
                  setState({
                    ...state,
                    showEditor: false,
                  });
                },
              });
            }}
            onCopy={() => {
              practiceRequisitionActions.copyPracticeRequisition(
                selectedRequisition
              );
              setState({
                ...state,
                showEditor: true,
              });
            }}
            onFormDataChange={(data) => {
              practiceRequisitionActions.updatePracticeRequisition(data);
            }}
          />
        )}
      </StyledGrid>
      <DownloadRequisitionsDialog
        open={state.openDownloadRequisition}
        onClose={() => setState({ ...state, openDownloadRequisition: false })}
        requisitionList={serverReqList}
      />
    </MainTemplate>
  );
};

export default PracticeMode;

// const StyledGrid = styled(Grid)`
//   margin: 100px auto;
//   justify-content: center;
//   align-items: flex-start;
//   display: flex;
//   flex-wrap: wrap;
//   box-sizing: border-box;
//   width: 1315px;
// `;
