import styled from "styled-components";

export const NAITLogo = () => {
  return (
    <div>
      <NAIT
        src="http://www.nait.ca/App_Themes/NAIT.caStyles/img/nait-logo-shield.png"
        alt="NAIT Logo"
      />
    </div>
  );
};

const NAIT = styled.img`
  max-height: 50px;
  max-width: 41px;
  margin-right: 10px;
`;
