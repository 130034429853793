import { Box, Button, CircularProgress, Paper } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useEffect, useState } from "react";
import PatientCareBarcode from "common/components/PatientCareBarcode";
import { StyledButtonDiv } from "common/components/sharedStyledComponents";
import { RequisitionData } from "common/components/types";
import ClearAllSharpIcon from "@material-ui/icons/ClearAllSharp";
import FilterList from "common/components/RequisitionDataList/FilterList";
import styled from "styled-components";
import { openConfirm } from "common/dialogs/ConfirmBox";

export type Props = {
  onAdd(): void;
  onLabelSelected(data: RequisitionData): void;
  onClearAll?(): void;
  requisitionList: RequisitionData[];
  selectedReqId?: number;
  type: "manage" | "practice" | "test";
};

type State = {
  filteredList: RequisitionData[];
};

const RequisitionDataList = (props: Props) => {
  const {
    onAdd,
    onLabelSelected,
    requisitionList,
    selectedReqId,
    onClearAll,
    type,
  } = props;

  const [state, setState] = useState<State>({
    filteredList: requisitionList ?? [],
  });

  useEffect(() => {
    setState({ ...state, filteredList: requisitionList });
  }, [requisitionList]);

  return (
    <div style={{ width: 390 }}>
      <StyledButtonDiv>
        <Box flexGrow={1}>
          <Button
            style={{ marginLeft: 10, marginRight: 10 }}
            variant="contained"
            color="primary"
            onClick={() => {
              onAdd();
            }}
          >
            Add Label
          </Button>
        </Box>
        {type === "practice" && requisitionList.length > 0 && (
          <Button
            color="primary"
            style={{ fontWeight: "bold" }}
            onClick={() => {
              openConfirm({
                title: "Are you sure you want to clear All Labels?",
                description: `All the patient labels you've made will be cleared and you won't be able to get them back.`,
                callback: () => {
                  onClearAll && onClearAll();
                },
              });
            }}
          >
            <ClearAllSharpIcon />
            Clear All
          </Button>
        )}
        {type === "manage" && requisitionList.length > 0 && (
          <FilterList
            onFilterByPractice={() => {
              setState({
                ...state,
                filteredList: requisitionList.filter(
                  (c) => c.practiceRequisition === true
                ),
              });
            }}
            onFilterByTest={() => {
              setState({
                ...state,
                filteredList: requisitionList.filter(
                  (c) => c.practiceRequisition === false
                ),
              });
            }}
            onFilterByAll={() => {
              setState({
                ...state,
                filteredList: requisitionList,
              });
            }}
          />
        )}
      </StyledButtonDiv>
      {!!requisitionList &&
        requisitionList.length === 0 &&
        type === "manage" && <CircularProgress />}
      {requisitionList.length > 0 && (
        <StyledPaper>
          <StyledList>
            {state.filteredList.map((rd, index) => {
              return (
                <ListItem
                  key={index}
                  button
                  selected={selectedReqId === rd.id}
                  onClick={() => {
                    onLabelSelected(rd);
                  }}
                >
                  <PatientCareBarcode
                    value={rd.accessionNumber}
                    requisitionData={rd}
                  />
                </ListItem>
              );
            })}
          </StyledList>
        </StyledPaper>
      )}
    </div>
  );
};

export default RequisitionDataList;

const StyledPaper = styled(Paper)`
  width: 390px;
  height: auto;
  margin-top: 30px;
  margin-bottom: 20px;
  border-radius: 20px 20px 20px 20px;
`;

const StyledList = styled(List)`
  max-height: 1150px;
  overflow: auto;
`;
