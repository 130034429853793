import { Typography } from "@material-ui/core";
import React from "react";
import { StyledGrid } from "../sharedStyledComponents";

export type Props = {};

const Unauhorized = (props: Props) => {
  return (
    <StyledGrid>
      <Typography variant={"h5"}>
        You are not authorized to view this page.
      </Typography>
    </StyledGrid>
  );
};

export default Unauhorized;
