import { Divider } from "@material-ui/core";
import styled from "styled-components";
import { RequisitionData } from "common/components/types";

export type Props = {
  requisitionList: RequisitionData[];
};

const PatientList = (props: Props) => {
  return (
    <div>
      {props.requisitionList &&
        props.requisitionList.map((rd, index) => {
          return (
            <ListItemContainer key={index}>
              <FlexDiv>
                <DisplayListItem text="PHN" value={rd.phn} />
                <DisplayListItem text="Last Name" value={rd.lastName} />
                <DisplayListItem text="First Name" value={rd.firstName} />
              </FlexDiv>
              <FlexDiv>
                <DisplayListItem text="Sex" value={rd.sex} />
                <DisplayListItem text="DOB" value={rd.dateOfBirth} />
                <DisplayListItem text="Postal Code" value={rd.postalCode} />
              </FlexDiv>
              <FlexDiv>
                <DisplayListItem
                  text="Ordering Physician"
                  value={rd.orderingPhysician}
                />
                <DisplayListItem
                  text="Physician Code"
                  value={rd.physicianCode}
                />
                <DisplayListItem text="To Collect" value={rd.toCollect} />
              </FlexDiv>
              <FlexDiv>
                <DisplayListItem
                  text="Collection Date"
                  value={rd.collectionDate}
                />
                <DisplayListItem
                  text="Collection Time"
                  value={rd.collectionTime}
                />
                <DisplayListItem
                  text="Location"
                  value={rd.collectionLocation}
                />
              </FlexDiv>
              <FlexDiv>
                <DisplayListItem
                  text="Medical Tests"
                  value={rd.testCodes?.map((c) => c.code).join(" ")}
                />
              </FlexDiv>
              <Divider color="gray" style={{ marginTop: 15 }} />
            </ListItemContainer>
          );
        })}
    </div>
  );
};

export default PatientList;

type DisplayListItemProps = {
  text: string;
  value: string | undefined;
};
const DisplayListItem = (props: DisplayListItemProps) => {
  return (
    <StyledDiv>
      <StyledLabel>{props.text}: </StyledLabel>
      {props.value}
    </StyledDiv>
  );
};

const ListItemContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 800px;
`;

const StyledLabel = styled.label`
  font-weight: bold;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const StyledDiv = styled.div`
  width: 220px;
`;
