import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequisitionData } from "common/components/types";
import { defaultRD, randomNumber } from "common/components/helpers";

const controlName = "testMode";

//no server call example
var addTestRequisition: (
  state: State,
  action: PayloadAction
) => void = (state, action) => {
  var lastId =
    state.lastId === 0
      ? 9999999990 + state.requisitionList.length + 1
      : state.lastId + 1;
  const newRD: RequisitionData = {
    ...defaultRD,
    id: lastId,
    accessionNumber: randomNumber(),
  };
  state.lastId = lastId;
  state.selectedReqId = newRD.id ?? 0;
  state.requisitionList = [newRD, ...state.requisitionList];
};

var removeTestRequisition: (
  state: State,
  action: PayloadAction<number>
) => void = (state, action) => {
  state.requisitionList = state.requisitionList.filter(
    (c) => c.id !== action.payload
  );
  state.selectedReqId = 0;
};

var copyTestRequisition: (
  state: State,
  action: PayloadAction<RequisitionData>
) => void = (state, action) => {
  const accessionNumber = randomNumber();
  var lastId = state.lastId + 1;
  const copiedRequisitiondata: RequisitionData = {
    ...action.payload,
    id:lastId,
    accessionNumber: accessionNumber,
  };
  state.lastId = lastId;
  state.requisitionList = [copiedRequisitiondata, ...state.requisitionList];
  state.selectedReqId = copiedRequisitiondata.id??0;
};

var updateTestRequisition: (
  state: State,
  action: PayloadAction<RequisitionData>
) => void = (state, action) => {
  state.requisitionList = state.requisitionList.map((c) => {
    if (c.accessionNumber === action.payload.accessionNumber)
      return action.payload;
    else return c;
  });
};

var selectTestRequisition: (
  state: State,
  action: PayloadAction<number>
) => void = (state, action) => {
  state.selectedReqId = action.payload;
};

var clearAllTestRequisitions: (
  state: State,
  action: PayloadAction
) => void = (state, action) => {
  state.requisitionList = [];
  state.selectedReqId = 0;
  state.lastId = 0;
};

type State = {
  requisitionList: RequisitionData[];
  selectedReqId: number;
  lastId: number;
};

var slice = createSlice({
  name: controlName,
  initialState: {
    requisitionList: [],
    selectedReqId: 0,
    lastId: 0,
  } as State,
  reducers: {
    addTestRequisition,
    removeTestRequisition,
    copyTestRequisition,
    updateTestRequisition,
    selectTestRequisition,
    clearAllTestRequisitions,
  },
  extraReducers: {},
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
    },
  },
};

export default ret;
