import TextField from "@material-ui/core/TextField";
import InteractiveListItem from "common/components/TestCodes/InteractiveListItem";
import List from "@material-ui/core/List";
import type { TestCode } from "common/components/types";
import { StyledTestCodeDiv } from "common/components/sharedStyledComponents";
import styled from "styled-components";
import { openConfirm } from "common/dialogs/ConfirmBox";
import { CircularProgress } from "@material-ui/core";

export type Props = {
  testCodeList: TestCode[];
  testCodeListDB: TestCode[];
  //good to have if we're making server calls (in Manage Requisitions), so we can give user feedback
  isUpdatingTestCodeList?: boolean;
  onTestCodeListUpdate: (updatedTestCodeList: TestCode[]) => void;
  validateTestCode?: boolean;
};

const TestCodes = (props: Props) => {
  const {
    testCodeList,
    onTestCodeListUpdate,
    testCodeListDB,
    isUpdatingTestCodeList,
  } = props;

  function AddTestCodeItem(
    id: number,
    code: string,
    description: string,
    category: string
  ) {
    const tc: TestCode = { id, code, description, category };
    onTestCodeListUpdate(testCodeList.concat(tc));
  }

  var inputCode: string = "";
  return (
    <StyledTestCodeDiv>
      <TextField
        id="TestCodeField"
        label="Type Code and Press Enter"
        variant="filled"
        style={{ width: 300, marginBottom: 10 }}
        onKeyPress={(event: any) => {
          if (event.key === "Enter") {
            inputCode = event.target.value;
            const desc = testCodeListDB.find((c) => c.code === inputCode)
              ?.description;
            const id = testCodeListDB.find((c) => c.code === inputCode)?.id;
            const category = testCodeListDB.find((c) => c.code === inputCode)
              ?.category;

            if (
              desc === undefined &&
              category === undefined &&
              props.validateTestCode
            ) {
              openConfirm({
                title: "Invalid Test Code",
                description: `Test Code not found. Please enter the correct test code and try again `,
                isAlert: true,
              });
            } else if (desc === undefined || category === undefined) {
              AddTestCodeItem(0, inputCode, "Unknown", "Unknown");
            } else {
              AddTestCodeItem(id ? id : 0, inputCode, desc, category);
            }
            event.target.value = "";
          }
        }}
      />
      <StyledList>
        {isUpdatingTestCodeList && <CircularProgress />}
        {!isUpdatingTestCodeList &&
          testCodeList &&
          testCodeList.map((tc) => {
            return (
              <InteractiveListItem
                disableButtons={isUpdatingTestCodeList}
                key={tc.code}
                code={tc.code}
                description={tc.description}
                onDelete={(code) => {
                  onTestCodeListUpdate(
                    testCodeList.filter((c) => c.code !== code)
                  );
                }}
              />
            );
          })}
      </StyledList>
    </StyledTestCodeDiv>
  );
};

export default TestCodes;

const StyledList = styled(List)`
  max-height: 675px;
  width: 300px;
  overflow: auto;
`;
