import PatientCareBarcode from "common/components/PatientCareBarcode";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import { RequisitionData } from "common/components/types";
import { useState } from "react";
import ClearSharpIcon from "@material-ui/icons/ClearSharp";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import styled from "styled-components";
import { Button, Divider, Grid, ListItem, Paper } from "@material-ui/core";
import Discrepancies from "pages/Review/components/Discrepancies/Discrepancies";
import { DiscrepancyList } from "pages/Review/components/Discrepancies/DiscrepancyList";
import ReviewStudentBarcode from "pages/Review/components/ReviewStudentBarcode";
import MainTemplate from "common/components/MainTemplate";
import { useActions, useReduxState } from "store";
import { defaultRD } from "common/components/helpers";
import { useHistory } from "react-router";
import { PracticeModeRoute } from "common/components/paths";
import ReviewAppBarButtons from "./components/ReviewAppBarButtons";
import { openConfirm } from "common/dialogs/ConfirmBox";
import ReviewLabelSkeleton from "./components/ReviewLabelSkeleton";

type State = {
  selectedReqId: number;
  showLabels: boolean;
  isCorrect: boolean;
  labelItemContainerheight: string;
};

const Review = () => {
  const practiceRequisitionState = useReduxState((e) => e.practiceMode);
  const practiceRequisitionActions = useActions().practiceMode;

  const [state, setState] = useState<State>({
    selectedReqId: 0,
    showLabels: false,
    isCorrect: false,
    labelItemContainerheight: "360px",
  });

  const history = useHistory();

  const studentRequisitionList = practiceRequisitionState.requisitionList;
  const serverRequisitionList =
    practiceRequisitionState.practiceRequisitionList.data;

  const studentRequisitionData = studentRequisitionList.filter(
    (c) => c.id === state.selectedReqId
  )[0];
  const serverRequisitionData = serverRequisitionList?.filter(
    (c) => c.phn === studentRequisitionData?.phn
  )[0];

  return (
    <MainTemplate
      title="Review"
      appBarRight={
        <ReviewAppBarButtons
          onNewSessionClick={() => {
            openConfirm({
              title: "Are you sure you want to start a New Session?",
              description:
                "All the patient labels you've made will be cleared and you won't be able to get them back.",
              okBtnText: "Start New Session",
              callback: () => {
                practiceRequisitionActions.clearAllPracticeRequisitions();
                history.push(PracticeModeRoute);
              },
            });
          }}
        />
      }
    >
      <div style={{ marginLeft: 150, marginTop: 80 }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 50 }}
          onClick={() => {
            history.push(PracticeModeRoute);
          }}
        >
          Back
        </Button>
      </div>
      <StyledGrid>
        <div style={{ marginRight: 30, width: 200 }}>
          <StyledLabelDiv>PATIENT(S) :</StyledLabelDiv>
          <Divider color={"gray"} style={{ marginBottom: 30 }} />
          <StyledPaper>
            {studentRequisitionList.map((rd, index) => {
              return (
                <StyledListItem
                  key={index}
                  button
                  selected={state.selectedReqId === rd.id}
                  onClick={() => {
                    const req = serverRequisitionList?.filter(
                      (c) => c.phn === rd.phn
                    )[0];

                    setState({
                      ...state,
                      selectedReqId: rd.id ? rd.id : 0,
                      showLabels: true,
                      isCorrect:
                        DiscrepancyList(rd, req ?? defaultRD).length === 0,
                      labelItemContainerheight: LabelItemContainerHeight(
                        rd,
                        req ?? defaultRD
                      ),
                    });
                  }}
                >
                  {index + 1}. {rd.lastName?.toUpperCase()},{" "}
                  {rd.firstName?.charAt(0)}
                  {state.selectedReqId === rd.id &&
                  state.showLabels === true ? (
                    state.isCorrect ? (
                      <StyledCheckIcon />
                    ) : (
                      <StyledCrossIcon />
                    )
                  ) : (
                    ""
                  )}
                </StyledListItem>
              );
            })}
          </StyledPaper>
        </div>
        {!state.showLabels && (
          <ReviewLabelSkeleton
            isStudentRequisitionListExists={studentRequisitionList.length > 0}
          />
        )}
        {state.showLabels && (
          <LabelComparison
            studentRequisitionData={studentRequisitionData}
            serverRequisitionData={serverRequisitionData ?? defaultRD}
            labelItemContainerheight={state.labelItemContainerheight}
          />
        )}
      </StyledGrid>
    </MainTemplate>
  );
};

export default Review;

const LabelComparison = (props: {
  studentRequisitionData: RequisitionData;
  serverRequisitionData: RequisitionData;
  labelItemContainerheight: string;
}) => {
  const { studentRequisitionData, serverRequisitionData } = props;
  const practiceRequisitionActions = useActions().practiceMode;

  const serverTCCategories: string[] = serverRequisitionData.testCodes
    ? serverRequisitionData.testCodes
        .map((c) => c.category)
        .filter((value, index, self) => self.indexOf(value) === index)
    : [];

  const studentTCCategories: string[] = studentRequisitionData.testCodes
    ? studentRequisitionData.testCodes
        .map((c) => c.category)
        .filter((value, index, self) => self.indexOf(value) === index)
    : [];

  const extraTCCategories: string[] = studentTCCategories?.filter(function (c) {
    return !serverTCCategories?.includes(c);
  });

  const uniqueCombinedTCCategories: string[] = [
    ...serverTCCategories?.concat(extraTCCategories),
  ];

  const history = useHistory();

  return (
    <div>
      <div style={{ display: "flex" }}>
        <LabelListContainer>
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <StyledLabelDiv>YOUR LABEL :</StyledLabelDiv>
            </div>
            <div>
              <StyledButton
                onClick={() => {
                  practiceRequisitionActions.selectPracticeRequisition(
                    studentRequisitionData.id ?? 0
                  );
                  history.push(PracticeModeRoute);
                }}
              >
                <StyledEditIcon />
                Edit
              </StyledButton>
            </div>
          </div>
          <Divider color={"gray"} style={{ marginBottom: 30 }} />
          {studentRequisitionData.phn === serverRequisitionData.phn && (
            <div>
              {uniqueCombinedTCCategories.map((c, index) => {
                const studentReq = {
                  ...studentRequisitionData,
                  testCodes:
                    studentRequisitionData.testCodes?.filter(
                      (x) => x.category === c
                    ) ?? [],
                };
                const serverReq = {
                  ...serverRequisitionData,
                  testCodes:
                    serverRequisitionData.testCodes?.filter(
                      (x) => x.category === c
                    ) ?? [],
                };

                return (
                  <LabelItemContainer
                    height={props.labelItemContainerheight}
                    key={index}
                  >
                    <StyledTaglabel>{c}</StyledTaglabel>
                    <StyledPaper>
                      <ReviewStudentBarcode
                        value={studentReq.accessionNumber}
                        studentRequisitionData={studentReq}
                        serverRequisitionData={serverReq}
                        discrepancyList={DiscrepancyList(studentReq, serverReq)}
                      />
                    </StyledPaper>
                  </LabelItemContainer>
                );
              })}
            </div>
          )}
        </LabelListContainer>
        <LabelListContainer>
          <StyledLabelDiv>CORRECT LABEL :</StyledLabelDiv>
          <Divider color={"gray"} style={{ marginBottom: 30 }} />
          {serverTCCategories.map((c, index) => {
            const serverReq = {
              ...serverRequisitionData,
              testCodes:
                serverRequisitionData.testCodes?.filter(
                  (x) => x.category === c
                ) ?? [],
            };

            return (
              <LabelItemContainer
                height={props.labelItemContainerheight}
                key={index}
              >
                <StyledTaglabel>{c}</StyledTaglabel>
                <StyledPaper>
                  <PatientCareBarcode
                    value={serverReq.accessionNumber}
                    requisitionData={serverReq}
                  />
                </StyledPaper>
              </LabelItemContainer>
            );
          })}
        </LabelListContainer>
      </div>
      <div>
        <Discrepancies
          studentRequisitionData={studentRequisitionData}
          serverRequisitionData={serverRequisitionData}
        />
      </div>
    </div>
  );
};

function LabelItemContainerHeight(
  studentReq: RequisitionData,
  serverReq: RequisitionData
) {
  if (
    serverReq.collectionDate === null &&
    serverReq.collectionTime === null &&
    studentReq.collectionDate === "" &&
    studentReq.collectionTime === ""
  )
    return "250px";
  else return "320px";
}

const StyledGrid = styled(Grid)`
  margin: 20px auto;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 1315px;
`;

const StyledLabelDiv = styled.div`
  color: #858585;
  font-weight: bold;
  margin-bottom: 15px;
`;

const StyledTaglabel = styled.label`
  background-color: white;
  font-weight: bold;
  margin-top: 30px;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: box-shadow;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms;
`;

const LabelListContainer = styled.div`
  margin-right: 30px;
  width: 360px;
`;

const LabelItemContainer = styled.div<{ height: string }>`
  height: ${(props) => props.height};
  width: 360px;
`;
const StyledPaper = styled(Paper)`
  margin-top: 10px;
`;
const StyledListItem = styled(ListItem)`
  border: 1px solid #e5e5e5;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const StyledButton = styled(Button)`
  font-weight: bold;
  padding: 0px;
  color: #3f51b5;
`;

const StyledEditIcon = styled(EditSharpIcon)`
  font-size: 1.25rem;
  margin-right: 5px;
`;

const StyledCheckIcon = styled(CheckSharpIcon)`
  color: green;
  margin-left: 10px;
`;

const StyledCrossIcon = styled(ClearSharpIcon)`
  color: red;
  margin-left: 10px;
`;
