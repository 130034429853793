import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { useEffect } from "react";
import styled from "styled-components";
import { useActions, useReduxState } from "store";
import MarkingList from "./components/MarkingList";
import MainTemplate from "common/components/MainTemplate";
import MarkingListAppBarButtons from "./components/MarkingListAppBarButtons";
import { useHistory } from "react-router";
import { PrintMarkingListRoute } from "common/components/paths";

const MarkingListPage = () => {
  const markingRequisitionState = useReduxState((e) => e.markingList);
  const markingRequisitionActions = useActions().markingList;

  useEffect(() => {
    markingRequisitionActions.getAllRequisitions({});
  }, []);

  const allReqList = markingRequisitionState.allRequisitionList.data
    ? markingRequisitionState.allRequisitionList.data
    : [];

  const history = useHistory();

  return (
    <MainTemplate
      title="Marking List"
      requiresAdminAccess={true}
      appBarRight={
        <MarkingListAppBarButtons
          onPrintListClick={() => {
            history.push(PrintMarkingListRoute);
          }}
        />
      }
    >
      <div>
        {markingRequisitionState.allRequisitionList.isLoading && (
          <StyledGrid>
            <CircularProgress />
          </StyledGrid>
        )}
        {!markingRequisitionState.allRequisitionList.isLoading && (
          <div>
            <div style={{ margin: "100px auto 0px auto", width: 340 }}>
              <StyledLabelDiv>Create a marking list from PHNs</StyledLabelDiv>
              <Divider color={"gray"} />
            </div>

            <StyledGrid>
              <MarkingList
                serverRequisitionList={allReqList}
                selectedReqIds={markingRequisitionState.selectedReqIds}
                onMarkingListUpdated={(ids: number[]) => {
                  markingRequisitionActions.updateSelectedReqIds(ids);
                }}
              />
            </StyledGrid>
          </div>
        )}
      </div>
    </MainTemplate>
  );
};

export default MarkingListPage;

const StyledGrid = styled(Grid)`
  margin: auto;
  margin-top: 35px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

const StyledLabelDiv = styled.div`
  color: #858585;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-bottom: 5px;
`;
