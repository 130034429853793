import styled from "styled-components";
import LaunchIcon from "@material-ui/icons/Launch";
import { Button } from "@material-ui/core";

type FinishAppBarButtonsProps = {
  onNewSessionClick: () => void;
};

export default function FinishAppBarButtons(props: FinishAppBarButtonsProps) {
  return (
    <>
      <Button onClick={props.onNewSessionClick}>
        <LaunchIcon style={{ marginRight: 10 }} />
        New Session
      </Button>
      <Spacer />
    </>
  );
}

const Spacer = styled.div`
  margin: 1em;
`;
