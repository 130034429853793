import { ReactNode, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import SchoolSharpIcon from "@material-ui/icons/SchoolSharp";
import SettingsSharpIcon from "@material-ui/icons/SettingsSharp";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import TopAppBar from "common/components/MainTemplate/TopAppBar";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  PracticeModeRoute,
  ManageRequisitionRoute,
  TestModeRoute,
  SettingsPath,
  MarkingListRoute,
} from "common/components/paths";
import SideMenuItem from "./SideMenuItem";
import { useHistory, useLocation } from "react-router-dom";
import { useViewSize } from "../helpers";
import { CircularProgress, SvgIconTypeMap } from "@material-ui/core";
import { useEffect } from "react";
import { useActions, useReduxState } from "store";
import { useMsal } from "@azure/msal-react";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { NAITLogo } from "common/components/Icons/Icons";
import styled from "styled-components";
import Unauhorized from "./Unauhorized";

const drawerWidth = 270;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#002D74",
      color: "white",
    },
  })
);

type NavItem = {
  text: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  route: string;
  isAdminNav?: boolean;
};

let navItems: NavItem[] = [
  { text: "Practice", icon: MenuBookIcon, route: PracticeModeRoute },
  { text: "Test", icon: SchoolSharpIcon, route: TestModeRoute },
  { text: "DIVIDER", isAdminNav: true, icon: SchoolSharpIcon, route: "" },
  {
    text: "Settings",
    icon: SettingsSharpIcon,
    route: SettingsPath,
    isAdminNav: true,
  },
  {
    text: "Manage Requisition",
    icon: EditSharpIcon,
    route: ManageRequisitionRoute,
    isAdminNav: true,
  },
  {
    text: "Marking List",
    icon: ListAltSharpIcon,
    route: MarkingListRoute,
    isAdminNav: true,
  },
];

type Props = {
  title: string;
  appBarRight?: ReactNode;
  requiresAdminAccess?: boolean;
  children: ReactNode;
};

type State = {
  mobileOpen: boolean;
};

export default function MainTemplate(props: Props) {
  const classes = useStyles();
  // const theme = useTheme();
  const [state, setState] = useState<State>({
    mobileOpen: false,
  });

  const handleDrawerToggle = () => {
    setState({ ...state, mobileOpen: !state.mobileOpen });
  };
  const { isSmallDesktop } = useViewSize();
  const history = useHistory();
  const mainTemplateState = useReduxState((e) => e.mainTemplate);
  const mainTemplateActions = useActions().mainTemplate;
  const { accounts } = useMsal();

  const routePath = useLocation().pathname;

  useEffect(() => {
    if (!mainTemplateState.currentUser.data)
      mainTemplateActions.checkUserAccess({ userName: accounts[0].username });
  }, []);

  if (
    mainTemplateState.currentUser.data &&
    !mainTemplateState.currentUser.data.isAdmin
  ) {
    navItems = navItems.filter((c) => !c.isAdminNav);
  }

  const drawer = (
    <div>
      <AppTitleDiv>
        <NAITLogo />
        <div style={{ maxHeight: "50px" }}>PATIENT CARE</div>
      </AppTitleDiv>
      {mainTemplateState.currentUser.isLoading && (
        <CircularProgress style={{ marginLeft: 20, color: "white" }} />
      )}
      {!mainTemplateState.currentUser.isLoading && (
        <List style={{ fontSize: 12 }}>
          {navItems.map((c, index) => {
            if (c.text === "DIVIDER")
              return (
                <Divider
                  key={index}
                  style={{ margin: "10px 0", backgroundColor: "white" }}
                />
              );
            else
              return (
                <SideMenuItem
                  key={index}
                  listItemText={c.text}
                  icon={c.icon}
                  isSelected={routePath === c.route}
                  onClick={() => {
                    setState({
                      mobileOpen: false,
                    });
                    history.push(c.route);
                  }}
                />
              );
          })}
        </List>
      )}
    </div>
  );

  // if (mainTemplateState.currentUser.isLoading) {
  //   return <CircularProgress />
  // }

  // if (props.requiresAdminAccess && mainTemplateState.currentUser.data?.isAdmin) {
  //   return <div>unauthorized</div>
  // }
  
  const showUnauthorized = props.requiresAdminAccess && !mainTemplateState.currentUser.data?.isAdmin;

  return (
    <div>
      <CssBaseline />
      <TopAppBar
        handleDrawerToggle={handleDrawerToggle}
        right={props.appBarRight}
        title={props.title}
      />
      {isSmallDesktop && (
        <Drawer
          variant="temporary"
          // anchor={theme.direction === "rtl" ? "right" : "left"}
          open={state.mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      )}
      {!isSmallDesktop && (
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      )}
      <StyledMainDiv isSmallDesktop={isSmallDesktop}>
        {showUnauthorized && <Unauhorized />}
        {!showUnauthorized && props.children}
      </StyledMainDiv>
    </div>
  );
}

const AppTitleDiv = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 10px;
  align-items: center;
`;

const StyledMainDiv = styled.div<{ isSmallDesktop: boolean }>`
  margin-left: ${(props) => (props.isSmallDesktop ? "0px" : "200px")};
  margin-top: 70px;
`;
// const StyledMainDiv = styled.div<{ isSmallDesktop: boolean }>`
//   margin-left: ${(props) => (props.isSmallDesktop ? "0px" : "270px")};
//   margin-top: 70px;
//   width: auto;
// `;
