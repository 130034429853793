import { Button, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

export const StyledActionBar = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 64px 0px 50px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #002d74;
`;

export const StyledMainDiv = styled.div`
  display: flex;
  margin: 250px auto;
  width: 1280px;
  background-color: white;
`;
export const FormContainer = styled.div`
  display: flex;
  border: 0.5px solid black;
  background-color: white;
  margin-left: 20px;
`;

export const FieldsContainer = styled.div`
  flex-grow: 1;
  margin-left: 70px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const CodeContatiner = styled.div`
  margin: 40px 10px 30px 100px;
`;

export const StyledTitle = styled.label`
  font-size: 28pt;
  font-weight: bold;
  color: #5ecefc;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const StyledRowDiv = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const StyledLabelDiv = styled.div`
  width: 180px;
  padding-top: 10px;
  font-weight: bold;
`;
export const StyledInputDiv = styled.div`
  margin-left: 0px;
  margin-right: 5px;
`;

export const StyledTestCodeDiv = styled.div`
  width: 350px;
  height: 300px;
  margin-bottom: 30px;
  background-color: white;
`;

export const StyledBarCodeDiv = styled.div`
  width: 360px;
  height: auto;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 20px;
  background-color: white;
`;

export const StyledButtonDiv = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
`;

export const LargeButton = styled(Button)`
  width: 126px;
  height: 57px;
  font-size: 15px;
  border-radius: 6px;
  margin-right: 150px;
  color: ${(props) => (props.disabled ? "darkgrey" : "white")} !important;
  background-color: ${(props) =>
    props.disabled ? "#EBEBEB" : "#0277bd"} !important;
`;

export const TextButton = styled(Button)`
  margin-right: 150px;
  color: white;
`;

export const StyledTypography = styled(Typography)`
  margin-left: 100px;
  margin-top: 50px;
`;

export const StyledGrid = styled(Grid)`
  margin: 100px auto;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 1315px;
`;
