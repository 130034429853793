import { PDFDocument } from "pdf-lib";
import { RequisitionData } from "common/components/types";

async function fillAndDownloadRequisition(requisitionData?: RequisitionData) {
  if (!requisitionData) return;

  const url = "dynalife_form.pdf";
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  const form = pdfDoc.getForm();

  console.log(form.getFields().map((c) => c.getName()));
  /*
      ["Ordering Physician  Practitioner", "Physician Code_2", "Ordering Address  Location", 
      "Report Location Code", "Co Name", "Report Address if different", "Address_2", "Client",
       "Col Location", "TIME 24 h", "Collector", "Clinical InformationDiagnosis", 
       "Other Test Not Listed", "Timed other", "Total volume", "L", "Start timedate",
       Stop timedate", "Other", "of hours", "Source", "Reason for request 1",
       "Time last dose STARTED 1", "Time last dose STARTED 2", "Time last dose STARTED 3",
       "How long on this dose regimen", "1", "2", "3", "4", "5", "PRE trough TGENT gentamicin",
       "POST 0510 h after does end PGENT gentamicin", "Extended Interval Dose 7mgKg INTERVAL 8h after dose start 8GENT gentamicin",
       "OTHERGENT gentamicin", "PRE trough TTOB tobramycin", "POST 0510 h after does end PTOB tobramycin", 
       "Extended Interval Dose 7mgKg INTERVAL 8h after dose start 8TOB tobramycin", "OTHERTOB tobramycin", "PRE trough TVANC vancomycin", "POST 0510 h after does end PVANC vancomycin", "Extended Interval Dose 7mgKg INTERVAL 8h after dose start 8VANC vancomycin", "OTHERVANC vancomycin", "PRE trough TAMIK amikacin", "POST 0510 h after does end PAMIK amikacin", "Extended Interval Dose 7mgKg INTERVAL 8h after dose start 8AMIK amikacin", "OTHERAMIK amikacin", 
       "MM_2", "DD_2", "YY_2", "Chart  Patient Phone  Lab", "Patient Phone  Number", "Lab number", 
       "top_male", "top_female", "top_lastname", "top_initial", "top_appt_datetime", "top_phn", 
       "top_dob_dd", "top_dob_mm", "top_dob_yy", "top_copyto_name", "top_address", 
       "top_copyto_physician_code", "top_city", "top_postalcode", "top_province", "top_firstname", 
       "top_copyto_address", "top_copyto_address2", "Check Box1400",
    */
  form.getTextField("top_phn").setText(requisitionData.phn);
  form
    .getTextField("top_firstname")
    .setText(requisitionData.firstName ? requisitionData.firstName : "");
  form
    .getTextField("top_lastname")
    .setText(requisitionData.lastName ? requisitionData.lastName : "");
  // form
  //   .getTextField("top_appt_datetime")
  //   .setText(
  //     requisitionData.collectionDate + " - " + requisitionData.collectionTime
  //   );

  var dateArr = requisitionData.collectionDate?.split("/");

  dateArr && form.getTextField("DD_2").setText(dateArr[0]);
  dateArr && form.getTextField("MM_2").setText(dateArr[1]);
  dateArr && form.getTextField("YY_2").setText(dateArr[2]);
  form
    .getTextField("TIME 24 h")
    .setText(
      requisitionData.collectionTime ? requisitionData.collectionTime : ""
    );

  const dobArr = requisitionData.dateOfBirth?.split("/");
  dobArr && form.getTextField("top_dob_dd").setText(dobArr[0]);
  dobArr && form.getTextField("top_dob_mm").setText(dobArr[1]);
  dobArr && form.getTextField("top_dob_yy").setText(dobArr[2]);
  form
    .getTextField("Ordering Physician  Practitioner")
    .setText(
      requisitionData.orderingPhysician ? requisitionData.orderingPhysician : ""
    );
  form
    .getTextField("Physician Code_2")
    .setText(
      requisitionData.physicianCode ? requisitionData.physicianCode : ""
    );
  form
    .getTextField("Col Location")
    .setText(
      requisitionData.collectionLocation
        ? requisitionData.collectionLocation
        : ""
    );
  if (requisitionData.sex === "M") form.getCheckBox("top_male").check();
  else if (requisitionData.sex === "F") form.getCheckBox("top_female").check();
  form
    .getTextField("top_postalcode")
    .setText(requisitionData.postalCode ? requisitionData.postalCode : "");

  requisitionData.testCodes &&
    requisitionData.testCodes.map((c) => {
      form.getCheckBox(c.code).check();
      return c;
    });

  form.flatten();
  const pdfBytes = await pdfDoc.save();
  Download(
    requisitionData.practiceRequisition ? "Practice" : "Test",
    requisitionData.phn ? requisitionData.phn : "undefined",
    pdfBytes,
    "application/pdf"
  );
}

const Download = (
  category: string,
  phn: string,
  arrayBuffer: Uint8Array,
  type: string
) => {
  var blob = new Blob([arrayBuffer], { type: type });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${category}-${phn}.pdf`;
  link.click();
};

export default fillAndDownloadRequisition;
