import { Button, Grid, Paper, Typography } from "@material-ui/core";
import PatientCareBarcode from "common/components/PatientCareBarcode";
import { RequisitionData } from "common/components/types";
import { ReactInstance, RefObject, useRef } from "react";
import PrintSharpIcon from "@material-ui/icons/PrintSharp";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import FinishSkeleton from "./FinishSkeleton";

export type Props = {
  requisitionList: RequisitionData[];
  onEditClick?(): void;
};
const Labels = (props: Props) => {
  const { requisitionList } = props;
  var keyIndex = 0;
  return (
    <div>
      {requisitionList &&
        requisitionList.map((rd, index) => {
          const studentTCCategories: string[] = rd.testCodes
            ? rd.testCodes
                .map((c) => c.category)
                .filter((value, index, self) => self.indexOf(value) === index)
            : [];
          return (
            <div key={index}>
              {studentTCCategories &&
                studentTCCategories.map((c) => {
                  const reqData = {
                    ...rd,
                    testCodes:
                      rd.testCodes?.filter((x) => x.category === c) ?? [],
                  };
                  keyIndex = keyIndex + 1;
                  return (
                    <div key={keyIndex}>
                      <StyledTagDiv>{c}</StyledTagDiv>
                      <StyledPaper>
                        <PatientCareBarcode
                          value={rd.accessionNumber}
                          requisitionData={reqData}
                        />
                      </StyledPaper>
                    </div>
                  );
                })}
              {(!studentTCCategories || studentTCCategories.length === 0) && (
                <StyledPaper key={rd.accessionNumber}>
                  <PatientCareBarcode
                    value={rd.accessionNumber}
                    requisitionData={rd}
                  />
                </StyledPaper>
              )}
            </div>
          );
        })}
    </div>
  );
};

const PrintLabel = (props: Props) => {
  const printRef = useRef<ReactInstance>(null);
  return (
    <div>
      <StyledDiv>
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: 50 }}
          onClick={props.onEditClick}
        >
          <ArrowBackSharpIcon style={{ marginRight: 10 }} />
          Back
        </Button>
        <ReactToPrint
          trigger={() => (
            <Button variant="contained" color="primary">
              <PrintSharpIcon style={{ marginRight: 10 }} />
              Print
            </Button>
          )}
          content={() => printRef.current}
          documentTitle="Test Collection Labels"
        />
      </StyledDiv>
      <div ref={printRef as RefObject<HTMLDivElement>}>
        <StyledGrid>
          <Typography variant="h5">TEST COLLECTION LABELS</Typography>
        </StyledGrid>
        <StyledGrid>
          {props.requisitionList.length === 0 && <FinishSkeleton />}
          <Labels requisitionList={props.requisitionList} />{" "}
        </StyledGrid>
      </div>
    </div>
  );
};

export default PrintLabel;

const StyledGrid = styled(Grid)`
  margin: auto;
  margin-top: 25px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

const StyledDiv = styled.div`
  margin-left: 150px;
`;

const StyledPaper = styled(Paper)`
  margin-bottom: 20px;
`;

const StyledTagDiv = styled.label`
  background-color: white;
  font-weight: bold;
  margin-top: 30px;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: box-shadow;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms;
`;
