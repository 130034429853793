import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FilterListSharpIcon from "@material-ui/icons/FilterListSharp";
import styled from "styled-components";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export type Props = {
  onFilterByPractice?(): void;
  onFilterByTest?(): void;
  onFilterByAll?(): void;
};

export type State = {
  selectedFilter: string;
  anchorEl: HTMLElement | null;
};

export type FilterListItem = {
  name: string;
  onClickAction?(): void;
};

const FilterList: React.FC<Props> = ({
  onFilterByPractice,
  onFilterByTest,
  onFilterByAll,
}) => {
  //const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [state, setState] = useState<State>({
    selectedFilter: "All",
    anchorEl: null,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setState({ ...state, anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setState({ ...state, anchorEl: null });
  };

  var filterListItems: FilterListItem[] = [
    {
      name: "All",
      onClickAction: onFilterByAll,
    },
    {
      name: "Practice",
      onClickAction: onFilterByPractice,
    },
    {
      name: "Test",
      onClickAction: onFilterByTest,
    },
  ];

  return (
    <div>
      <FilterButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
      >
        <FilterListSharpIcon />
        Filter List
      </FilterButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={state.anchorEl}
        keepMounted
        open={Boolean(state.anchorEl)}
        onClose={handleClose}
      >
        {filterListItems.map((c, index) => (
          <StyledMenuItem
            key={index}
            selected={state.selectedFilter === c.name}
            onClick={() => {
              c.onClickAction && c.onClickAction();
              setState({ ...state, selectedFilter: c.name, anchorEl: null });
            }}
          >
            <ListItemText primary={c.name} />
          </StyledMenuItem>
        ))}
        {/* <StyledMenuItem onClick={onFilterByAll}>
          <ListItemText primary="All" />
        </StyledMenuItem>
        <StyledMenuItem onClick={onFilterByPractice}>
          <ListItemText primary="Practice" />
        </StyledMenuItem>
        <StyledMenuItem onClick={onFilterByTest}>
          <ListItemText primary="Test" />
        </StyledMenuItem> */}
      </StyledMenu>
    </div>
  );
};

export default FilterList;

const FilterButton = styled(Button)`
  font-weight: bold;
  align-items: flex-end;
`;
