import { useReduxState, useActions } from "store";
import { Tabs } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { useState } from "react";
import AccessSettings from "./components/AccessSettings";
import TestCodeSettings from "./components/TestCodeSettings";
import styled from "styled-components";
import { useEffect } from "react";
import MainTemplate from "common/components/MainTemplate";

export type Props = {};

type PageState = {
  selectedTab: number;
};

const Settings = (props: Props) => {
  const state = useReduxState((e) => e.settings);
  const actions = useActions().settings;
  //const mainTemplateState = useReduxState((e) => e.mainTemplate);
  //const hist = useHistory();
  const [pageState, setPageState] = useState<PageState>({
    selectedTab: 0,
  });

  useEffect(() => {
    actions.getAdminUsers({});
    actions.getTestCodes({});
  }, []);

  // if (!mainTemplateState.currentUser.isLoading && !mainTemplateState.currentUser.data?.isAdmin)
  // {
  //   hist.push("/AccessDenied");
  // }

  return (
    <MainTemplate title="Settings" requiresAdminAccess={true}>
      <Content>
        <Tabs
          value={pageState.selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => {
            setPageState({ ...pageState, selectedTab: v });
          }}
        >
          <Tab label="Access" />
          <Tab label="Test Codes" />
        </Tabs>
      </Content>
      <Content>
        {pageState.selectedTab === 0 && (
          <AccessSettings
            adminUsers={state.adminUserList.data}
            isAddingUser={state.addAdminUser.isLoading}
            isLoadingUsers={state.adminUserList.isLoading}
            onAddUser={(email, onComplete) => {
              actions.addAdminUser({ email, onComplete: onComplete });
            }}
            onRemoveUser={(id) => {
              actions.removeAdminUser({ id });
            }}
          />
        )}
        {pageState.selectedTab === 1 && (
          <TestCodeSettings
            testCodeList={state.testCodeList.data}
            isAddingCode={state.addTestCode.isLoading}
            isUpdatingCode={state.updateTestCode.isLoading}
            onSaveCode={(newCode, onComplete) => {
              if (newCode.id > 0)
                actions.updateTestCode({
                  id: newCode.id,
                  code: newCode.code,
                  description: newCode.description,
                  category: newCode.category ?? "",
                  onComplete: onComplete,
                });
              else
                actions.addTestCode({
                  code: newCode.code,
                  description: newCode.description,
                  category: newCode.category ?? "",
                  onComplete: onComplete,
                });
            }}
            onRemoveCode={(id) => {
              actions.removeTestCode({ id });
            }}
          />
        )}
      </Content>
    </MainTemplate>
  );
};

export default Settings;

const Content = styled.div`
  margin: auto;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
