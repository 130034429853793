import {
  StyledInputDiv,
  StyledRowDiv,
} from "common/components/sharedStyledComponents";
import { TextField } from "@material-ui/core";
import { useEffect, useState } from "react";

export type RowItemProps = {
  onBlur(value: string): void;
  value?: string;
  placeholder?: string;
  disabled?:boolean;
};

const RowItem = (props: RowItemProps) => {
  const [state, setState] = useState<string | undefined>(props.value);

  useEffect(() => {
    setState(props.value);
  }, [props.value]);
  return (
    <StyledRowDiv>
      <StyledInputDiv>
        <TextField
          size="small"
          variant="filled"
          label={props.placeholder}
          value={state}
          onChange={(e) => {
            setState(e.target.value);
          }}
          onBlur={(e) => {
            props.onBlur(e.target.value);
          }}
          style={{ width: "280px" }}
          disabled={props.disabled}
        />
      </StyledInputDiv>
    </StyledRowDiv>
  );
};

export default RowItem;
