import "App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PracticeMode from "pages/practiceMode";
import TestMode from "pages/testMode";
import ManageRequisition from "pages/manageRequisition";
import MarkingList from "pages/markingList";
import LoggedIn from "pages/LoggedIn";
import Settings from "pages/settings";

import { Provider } from "react-redux";
import store from "store";
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import ConfirmBox from "common/dialogs/ConfirmBox";
import AccessDenied from "pages/AccessDenied/AccessDenied";
import Review from "pages/Review";
import Finish from "pages/Finish";
import PrintMarkingList from "pages/PrintMarkingList";

function App() {
  const isAuthenticated = useIsAuthenticated();
  useMsal();
  useMsalAuthentication(InteractionType.Redirect);

  if (!isAuthenticated) {
    return <div></div>;
  }

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/loggedin">
            <LoggedIn />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path={"/practicemode"}>
            <PracticeMode />
          </Route>
          <Route path={"/review"}>
            <Review />
          </Route>
          <Route path={"/testmode"}>
            <TestMode />
          </Route>
          <Route path={"/finish"}>
            <Finish />
          </Route>
          <Route path={"/managerequisition"}>
            <ManageRequisition />
          </Route>
          <Route path={"/markinglist"}>
            <MarkingList />
          </Route>
          <Route path={"/printmarkinglist"}>
            <PrintMarkingList />
          </Route>
          {/* <Route path={"/RTPTest"}>
            <MainTemplate title="RTPTest">
              <RTPTest />
            </MainTemplate>
          </Route>
          <Route path={"/PrintList"}>
            <MainTemplate title="PrintList">
              <PrintList requisitionList={[]} />
            </MainTemplate>
          </Route>
          <Route path={"/PrintLabel"}>
            <MainTemplate title="PrintLabel">
              <PrintLabel requisitionList={[]} />
            </MainTemplate>
          </Route> */}
          <Route path={"/accessdenied"}>
            <AccessDenied />
          </Route>
          <Route path={"/"}>
            <PracticeMode />
          </Route>
        </Switch>
      </Router>
      <ConfirmBox />
    </Provider>
  );
}

export default App;
