import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import { TestCode } from "common/components/types";
import { openConfirm } from "common/dialogs/ConfirmBox";

export type TestCodeListItemProps = {
  testCode: TestCode;
  onDelete: (id: number) => void;
  onEdit: (id: number) => void;
};

const TestCodeListItem = (props: TestCodeListItemProps) => {
  return (
    <div>
      <ListItem
        style={{
          border: "1px solid #E5E5E5",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <ListItemText>
          <div style={{ display: "flex" }}>
            <div style={{ width: 80 }}>{props.testCode.code}</div>
            <div style={{ width: 300, marginRight: 25 }}>
              {props.testCode.description}
            </div>
            <div style={{ width: 75, marginRight: 15 }}>
              {props.testCode.category}
            </div>
          </div>
        </ListItemText>
        {/* <ListItemText primary={props.testCode.code}/>
        <ListItemText
          primary={props.testCode.description}
          style={{ width: 100 }}
        /> */}
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="edit"
            color="primary"
            style={{ marginLeft: 10 }}
            onClick={(e: any) => {
              props.onEdit(props.testCode.id);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete"
            color="primary"
            onClick={(e: any) => {
              openConfirm({
                title: "Delete Test Code",
                description:
                  "Deleting a Test Code will remove it from all requisitions. Continue?",
                callback: () => {
                  props.onDelete(props.testCode.id);
                },
              });
            }}
          >
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
};
export default TestCodeListItem;
