import TextField from "@material-ui/core/TextField";
import type { RequisitionData } from "common/components/types";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

type Props = {
  serverRequisitionList: RequisitionData[];
  selectedReqIds: number[];
  onMarkingListUpdated: (ids: number[]) => void;
};

type State = {
  filterText: string;
  filteredReqList: RequisitionData[];
};

const MarkingList = (props: Props) => {
  const [state, setState] = useState<State>({
    filterText: "",
    filteredReqList: props.serverRequisitionList,
  });

  useEffect(() => {
    setState({ ...state, filteredReqList: props.serverRequisitionList });
  }, [props.serverRequisitionList]);

  console.log(props.selectedReqIds);

  return (
    <StyledPHNListContainer>
      <TextField
        id="reqListFilter"
        label="Filter by PHN or name"
        variant="filled"
        style={{ width: 300, marginBottom: 20 }}
        value={state.filterText}
        onChange={(event: any) => {
          var val = event.target.value as string;
          setState({
            ...state,
            filterText: val,
            filteredReqList: props.serverRequisitionList.filter(
              (c) =>
                c.phn?.includes(val) ||
                c.firstName?.toLowerCase().includes(val.toLowerCase()) ||
                c.lastName?.includes(val.toLowerCase())
            ),
          });
        }}
      />
      <FormControl>
        <FormGroup>
          {state.filteredReqList.map((c, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    color="primary"
                    checked={props.selectedReqIds.includes(c.id ? c.id : 0)}
                    onChange={(event) => {
                      var checked: boolean = event.target.checked;
                      var cid = c.id ? c.id : 0;
                      var newList: number[] = [...props.selectedReqIds];
                      if (checked) {
                        newList.push(cid);
                      } else {
                        newList = newList.filter((d) => d !== cid);
                      }
                      // setState({
                      //   ...state,
                      //   checkedIds: newList,
                      // });
                      props.onMarkingListUpdated(newList);
                    }}
                  />
                }
                label={`${c.phn} - ${c.firstName} ${c.lastName}`}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </StyledPHNListContainer>
  );
};

export default MarkingList;


export const StyledPHNListContainer = styled.div`
  background-color: #fafafa;
  width: 320px;
  height: 400px;
  margin-bottom: 30px;
`;
