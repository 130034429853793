import { BracketNotation, RequisitionData } from "common/components/types";

export function DiscrepancyList(
  studentRequisitionData: BracketNotation & RequisitionData,
  serverRequisitionData: BracketNotation & RequisitionData
) {
  const keysToSkip: string[] = [
    "requisitionMetaInfo",
    "id",
    "accessionNumber",
    "hideFromStudents",
    "practiceRequisition",
    "testCodes",
  ];

  var diffList = Object.keys(studentRequisitionData).filter(function (key) {
    return (
      !keysToSkip.includes(key) &&
      !(
        studentRequisitionData[key] === "" &&
        serverRequisitionData[key] === null
      ) &&
      studentRequisitionData[key].toLowerCase() !== serverRequisitionData[key].toLowerCase() 
    );
  });

  const studentCodes: string[] = studentRequisitionData.testCodes
    ? studentRequisitionData.testCodes?.map((c) => c.code)
    : [];
  const serverCodes: string[] = serverRequisitionData?.testCodes
    ? serverRequisitionData?.testCodes?.map((c) => c.code)
    : [];

  const missingCodes: string[] = serverCodes.filter(function (c) {
    return !studentCodes.includes(c);
  });

  const extraCodes: string[] = studentCodes.filter(function (c) {
    return !serverCodes.includes(c);
  });

  if (missingCodes.length > 0 || extraCodes.length > 0)
    diffList = diffList.concat("testCodes");

  return diffList;
}
