import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

export type TestCodeListItemProps = {
  code: string;
  description: string;
  onDelete: (code: string) => void;
  disableButtons?:boolean
};

const InteractiveListItem = (props: TestCodeListItemProps) => {
  return (
    <div>
      <ListItem
        style={{ border: "1px solid #E5E5E5", marginTop: 10, marginBottom: 10 }}
      >
        <ListItemText primary={props.code} style={{ width: 20 }} />
        <ListItemText
          primary={props.description}
          style={{ marginLeft: 30, width: 200 }}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            color="primary"
            disabled={props.disableButtons}
            onClick={(e: any) => {
              props.onDelete(props.code);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
};
export default InteractiveListItem;
