import { Button, Grid, Typography } from "@material-ui/core";
import { ReactInstance, RefObject, useRef } from "react";
import PrintSharpIcon from "@material-ui/icons/PrintSharp";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import PatientList from "common/components/PatientList";
import { useReduxState } from "store";
import MainTemplate from "common/components/MainTemplate";
import { useHistory } from "react-router";
import { MarkingListRoute } from "common/components/paths";
import NoPatientRecordSkeleton from "pages/PrintMarkingList/components/NoMarkingListSkeleton";

const PrintMarkingList = () => {
  const markingRequisitionState = useReduxState((e) => e.markingList);

  const selectedReqList = markingRequisitionState.allRequisitionList.data?.filter(
    (c) => c.id && markingRequisitionState.selectedReqIds.includes(c.id)
  );

  const history = useHistory();

  const printRef = useRef<ReactInstance>(null);
  return (
    <MainTemplate
      title=" Print Marking List"
      appBarRight={
        <>
          <ReactToPrint
            trigger={() => (
              <Button>
                <PrintSharpIcon style={{ marginRight: 10 }} />
                Print
              </Button>
            )}
            content={() => printRef.current}
            documentTitle="Marking List"
          />
          <Spacer />
        </>
      }
    >
      <>
        <div style={{ marginLeft: 150, marginTop: 100 }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 50 }}
            onClick={() => {
              history.push(MarkingListRoute);
            }}
          >
            Back
          </Button>
        </div>
        <div ref={printRef as RefObject<HTMLDivElement>}>
          <StyledGrid>
            <Typography variant="h5">Marking List</Typography>
          </StyledGrid>
          <StyledGrid>
            {selectedReqList && selectedReqList.length === 0 && (
              <NoPatientRecordSkeleton />
            )}
            {selectedReqList ? "" : <NoPatientRecordSkeleton />}
            <PatientList requisitionList={selectedReqList ?? []} />
          </StyledGrid>
        </div>
      </>
    </MainTemplate>
  );
};

export default PrintMarkingList;

const StyledGrid = styled(Grid)`
  margin: auto;
  margin-top: 25px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

const Spacer = styled.div`
  margin: 1em;
`;
