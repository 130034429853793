import { StyledTypography } from "common/components/sharedStyledComponents";
import styled from "styled-components";
export default function FinishSkeleton() {
  return (
    <Skeleton>
      <StyledTypography variant="h5">
        No patient record found. Please click Back button, create labels and try
        again
      </StyledTypography>
    </Skeleton>
  );
}
const Skeleton = styled.div`
  margin-top: 20px;
`;
