import React from "react";
import Switch from "@material-ui/core/Switch";

type Props = {
  labelText: string;
  labelColor: string;
  toggleState: boolean;
  onToggle: (togState: boolean) => void;
};

const ToggleSwitch: React.FC<Props> = ({
  labelText,
  labelColor,
  toggleState,
  onToggle,
}) => {
  return (
    <div>
      <Switch
        checked={toggleState}
        onChange={(e: any) => {
          onToggle(toggleState);
        }}
        color="primary"
        inputProps={{ "aria-label": "primary checkbox" }}
      />
      <label
        style={{ color: labelColor, fontWeight: "bold", marginLeft: "5px" }}
      >
        {labelText}
      </label>
    </div>
  );
};

export default ToggleSwitch;
