import { Box, Button, List, TextField, Typography } from "@material-ui/core";
import { TestCode } from "common/components/types";
import React, { useState } from "react";
import styled from "styled-components";
import TestCodeListItem from "./TestCodeListItem";

export type Props = {
  testCodeList?: TestCode[];
  isAddingCode: boolean;
  isUpdatingCode: boolean;
  onSaveCode: (newCode: TestCode, onComplete: () => void) => void;
  onRemoveCode: (id: number) => void;
};

export type State = {
  editId: number;
};

const TestCodeSettings = (props: Props) => {
  const [state, setState] = useState<State>({
    editId: -1,
  });

  return (
    <div>
      <StyledTypography>Add new test code:</StyledTypography>
      <TestCodeEditor
        disableInput={props.isAddingCode}
        buttonLabel={"Add"}
        onSaveCode={(newCode, onComplete) => {
          props.onSaveCode(newCode, onComplete);
        }}
      />
      <List style={{ overflow: "auto", width: 650 }}>
        {props.testCodeList &&
          props.testCodeList.map((c, index) => {
            if (state.editId === c.id)
              return (
                <TestCodeEditor
                  testCode={c}
                  disableInput={props.isUpdatingCode}
                  buttonLabel={"Save"}
                  onSaveCode={(newCode, onComplete) => {
                    props.onSaveCode(newCode, () => {
                      onComplete();
                      setState({ editId: -1 });
                    });
                  }}
                />
              );
            else
              return (
                <TestCodeListItem
                  key={index}
                  testCode={c}
                  onEdit={(id) => {
                    setState({ editId: id });
                  }}
                  onDelete={(id) => {
                    props.onRemoveCode(id);
                  }}
                />
              );
          })}
      </List>
    </div>
  );
};

type EditorProps = {
  testCode?: TestCode;
  disableInput: boolean;
  buttonLabel: string;
  onSaveCode: (newCode: TestCode, onComplete: () => void) => void;
};

const TestCodeEditor = (props: EditorProps) => {
  const defaultState: TestCode = {
    code: "",
    description: "",
    category: "",
    id: 0,
  };
  const [state, setState] = useState<TestCode>(
    props.testCode ? props.testCode : defaultState
  );
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        label="Code"
        variant="filled"
        disabled={props.disableInput}
        style={{ width: 100, paddingRight: 10, marginBottom: 10 }}
        value={state.code}
        onChange={(e) => setState({ ...state, code: e.target.value })}
      />
      <TextField
        label="Description"
        variant="filled"
        disabled={props.disableInput}
        style={{ width: 300, paddingRight: 10, marginBottom: 10 }}
        value={state.description}
        onChange={(e) => setState({ ...state, description: e.target.value })}
      />
      <TextField
        label="Category"
        variant="filled"
        disabled={props.disableInput}
        style={{ width: 150, marginBottom: 10 }}
        value={state.category}
        onChange={(e) => setState({ ...state, category: e.target.value })}
      />
      <Box style={{ marginTop: -5 }}>
        <Button
          style={{ marginLeft: 10, marginRight: 10 }}
          variant="contained"
          color="primary"
          disabled={props.disableInput}
          onClick={() => {
            props.onSaveCode(state, () => {
              setState(defaultState);
            });
          }}
        >
          {props.buttonLabel}
        </Button>
      </Box>
    </div>
  );
};

export default TestCodeSettings;

const StyledTypography = styled(Typography)`
  margin: 30px 0px 10px 0px;
`;
