import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

export type DialogBoxProps = {
  open: boolean;
  onClose(): void;
  dialogTitle: string;
  dialogContent: string | JSX.Element;
  agreeButtonLabel: string;
  agreeButtonAction(): void;
  disagreeButtonLabel: string;
  disagreeButtonAction(): void;
  disableControls?: boolean;
};

const DialogBox = (props: DialogBoxProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
      <DialogContent>
        <div style={{ paddingBottom: 10 }}>{props.dialogContent}</div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={props.disableControls}
          onClick={props.agreeButtonAction}
          color="primary"
        >
          {props.agreeButtonLabel}
        </Button>
        <Button
          disabled={props.disableControls}
          onClick={props.disagreeButtonAction}
          color="primary"
          autoFocus
        >
          {props.disagreeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
