import {
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

interface SideMenuItemProps {
  listItemText: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  onClick(): void;
  isSelected: boolean;
}

const SideMenuItem = (props: SideMenuItemProps) => {
  return (
    <ListItem button key={props.listItemText} onClick={props.onClick}>
      <ListItemIcon style={{ color: props.isSelected ? "#669df6" : "white" }}>
        <props.icon />
      </ListItemIcon>
      <ListItemText
        primary={props.listItemText.toUpperCase()}
        style={{ color: props.isSelected ? "#669df6" : "white" }}
      />
    </ListItem>
  );
};

export default SideMenuItem;
