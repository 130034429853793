import styled from "styled-components";
import GetAppSharpIcon from "@material-ui/icons/GetAppSharp";
import { Button } from "@material-ui/core";
type PracticeAppBarButtonsProps = {
  onDownloadPracticeReqClick: () => void;
  onReviewClick: () => void;
  enableReviewButton: boolean;
};
export default function PracticeAppBarButtons(
  props: PracticeAppBarButtonsProps
) {
  return (
    <>
      <Button onClick={props.onDownloadPracticeReqClick}>
        <GetAppSharpIcon style={{ marginRight: 10 }} />
        Practice Requisition
      </Button>
      <Spacer />
      <Button
        variant="contained"
        size="large"
        disabled={!props.enableReviewButton}
        onClick={props.onReviewClick}
      >
        Review
      </Button>
      <Spacer />
    </>
  );
}

const Spacer = styled.div`
  margin: 1em;
`;
