import mainTemplate from "common/components/MainTemplate/mainTemplate.duck";
import confirmBox from "common/dialogs/ConfirmBox/confirmBox.duck";

const ret = {
  reducer: {
    ...mainTemplate.reducer,
    ...confirmBox.reducer,
  },
  actions: {
    ...mainTemplate.actions,
    ...confirmBox.actions,
  },
};

export default ret;
