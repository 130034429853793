import { defaultRD } from "common/components/helpers";
import RequistionDataEditor from "common/components/RequisitionDataEditor";
import RequistionDataList from "common/components/RequisitionDataList";
import { useEffect, useState } from "react";
import { openConfirm } from "common/dialogs/ConfirmBox";
import { useActions, useReduxState } from "store";
import RequisitionDataEditorSkeleton from "common/components/RequisitionDataEditorSkeleton/RequisitionDataEditorSkeleton";
import MainTemplate from "common/components/MainTemplate";
import TestAppBarButtons from "./components/TestAppBarButtons";
import { useHistory } from "react-router";
import { FinishRoute } from "common/components/paths";
import { StyledGrid } from "common/components/sharedStyledComponents";

type State = {
  showEditor: boolean;
};

const TestMode = () => {
  const testRequisitionState = useReduxState((e) => e.testMode);
  const testRequisitionActions = useActions().testMode;
  const settingsActions = useActions().settings;
  const testCodeListDB = useReduxState((e) => e.settings).testCodeList.data;

  const [state, setState] = useState<State>({
    showEditor: false,
  });

  useEffect(() => {
    settingsActions.getTestCodes({});
    if (testRequisitionState.selectedReqId > 0) {
      testRequisitionActions.selectTestRequisition(
        testRequisitionState.selectedReqId ?? 0
      );
      setState({
        ...state,
        showEditor: true,
      });
    }
  }, []);

  const selectedRequisition = testRequisitionState.requisitionList.filter(
    (c) => c.id === testRequisitionState.selectedReqId
  )[0];

  const history = useHistory();

  return (
    <MainTemplate
      title="Test"
      appBarRight={
        <TestAppBarButtons
          onFinishClick={() => {
            history.push(FinishRoute);
          }}
          enableFinishButton={testRequisitionState.requisitionList.length > 0}
        />
      }
    >
      <StyledGrid>
        <RequistionDataList
          type="test"
          requisitionList={testRequisitionState.requisitionList}
          onAdd={() => {
            testRequisitionActions.addTestRequisition();
            setState({
              showEditor: true,
            });
          }}
          onLabelSelected={(data) => {
            testRequisitionActions.selectTestRequisition(data.id ?? 0);
            setState({
              ...state,
              showEditor: true,
            });
          }}
          selectedReqId={testRequisitionState.selectedReqId}
        />
        {!state.showEditor && <RequisitionDataEditorSkeleton />}

        {state.showEditor && (
          <RequistionDataEditor
            testCodeListDB={testCodeListDB ?? []}
            isManageRequisition={false}
            requisitionData={
              selectedRequisition ? selectedRequisition : defaultRD
            }
            onReset={() => {
              openConfirm({
                title: "Reset Requisition",
                description: `All the data in the fields will be cleared and you won't be able to get the data back. Continue?`,
                callback: () => {
                  testRequisitionActions.updateTestRequisition(
                    { ...defaultRD, accessionNumber: selectedRequisition.accessionNumber, id:selectedRequisition.id  }
                  );
                },
              });
            }}
            onDelete={() => {
              openConfirm({
                title: "Delete Requisition",
                description: `Accession #: ${selectedRequisition?.accessionNumber}. Continue?`,
                callback: () => {
                  testRequisitionActions.removeTestRequisition(
                    testRequisitionState.selectedReqId
                  );
                  setState({
                    ...state,
                    showEditor: false,
                  });
                },
              });
            }}
            onCopy={() => {
              testRequisitionActions.copyTestRequisition(selectedRequisition);
              setState({
                showEditor: true,
              });
            }}
            onFormDataChange={(data) => {
              testRequisitionActions.updateTestRequisition(data);
            }}
          />
        )}
      </StyledGrid>
    </MainTemplate>
  );
};

export default TestMode;


