import styled from "styled-components";
import GetAppSharpIcon from "@material-ui/icons/GetAppSharp";
import { Button } from "@material-ui/core";
import { RequisitionData } from "common/components/types";
import { useState } from "react";
import fillAndDownloadRequisition from "common/components/FillAndDownloadRequisition";
type ManageReqAppBarButtonsProps = {
  enableDownloadReqButton: boolean;
  selectedRequisition: RequisitionData;
};
export default function ManageReqAppBarButtons(
  props: ManageReqAppBarButtonsProps
) {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  return (
    <>
      <Button
        style={{
          color:
            props.enableDownloadReqButton && !isDownloading
              ? "black"
              : "darkgray",
        }}
        disabled={isDownloading ? true : !props.enableDownloadReqButton}
        onClick={() => {
          setIsDownloading(true);
          fillAndDownloadRequisition(props.selectedRequisition).then(
            (response) => {
              setIsDownloading(false);
            }
          );
        }}
      >
        <GetAppSharpIcon style={{ marginRight: 10 }} />
        {isDownloading ? "Downloading..." : "Download Requisition"}
      </Button>
      <Spacer />
    </>
  );
}

const Spacer = styled.div`
  margin: 1em;
`;
