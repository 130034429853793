import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RotateLeftSharpIcon from "@material-ui/icons/RotateLeftSharp";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import FileCopySharpIcon from "@material-ui/icons/FileCopySharp";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export type Props = {
  onReset(): void;
  onDelete(): void;
  onCopy(): void;
};

const EditorMenu: React.FC<Props> = ({ onReset, onDelete, onCopy }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <StyledMenuItem onClick={onCopy}>
          <ListItemIcon>
            <FileCopySharpIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Copy" />
        </StyledMenuItem>
        <StyledMenuItem onClick={onDelete}>
          <ListItemIcon>
            <DeleteSharpIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </StyledMenuItem>
        <StyledMenuItem onClick={onReset}>
          <ListItemIcon>
            <RotateLeftSharpIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Reset" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

export default EditorMenu;
