import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncAction,
  ReducerState,
} from "@nait-aits/redux";
import { RequisitionData } from "common/components/types";
import { defaultRD, randomNumber } from "common/components/helpers";
import getAuthBearerToken from "store/getAuthBearerToken";

const controlName = "practiceMode";

//MK: should be part of .env
//DA & BG: baseURL is now part of .env
const baseURL = `${process.env.REACT_APP_API_BASE}/requisition`
// type Return = {};
// type Data = ReducerState<Return>;

//this is the type of request to server call
type GetRequisitionPostData = {
  phn: string;
};

var getRequisition = createAsyncAction<
  RequisitionData, //return type
  GetRequisitionPostData, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getPracticeRequisition",
  url: baseURL + "/GetPracticeRequisition",
  pending: (state, action) => {
    state.getRequisition = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.getRequisition.isLoading = false;
    state.getRequisition.data = action.payload;
  },
  rejected: (state, action) => {
    state.getRequisition.isLoading = false;
    state.getRequisition.error = action.payload;
  },
  getAuthBearerToken
});

var getPracticeRequisitions = createAsyncAction<
  RequisitionData[], //return type
  {}, //params
  State
>({
  actionPrefix: controlName,
  actionName: "getPracticeRequisitions",
  url: baseURL + "/GetPracticeRequisitions",
  pending: (state, action) => {
    state.practiceRequisitionList = {
      isLoading: true,
    };
  },
  fulfilled: (state, action) => {
    state.practiceRequisitionList.isLoading = false;
    state.practiceRequisitionList.data = action.payload;
  },
  rejected: (state, action) => {
    state.practiceRequisitionList.isLoading = false;
    state.practiceRequisitionList.error = action.payload;
  },
  getAuthBearerToken
});

//no server call example
var addPracticeRequisition: (state: State, action: PayloadAction) => void = (
  state,
  action
) => {
  var lastId =
    state.lastId === 0
      ? 9999999990 + state.requisitionList.length + 1
      : state.lastId + 1;
  const newRD: RequisitionData = {
    ...defaultRD,
    id: lastId,
    accessionNumber: randomNumber(),
  };
  state.lastId = lastId;
  state.requisitionList = [newRD, ...state.requisitionList];
  state.selectedReqId = newRD.id ? newRD.id : 0;
};

var removePracticeRequisition: (
  state: State,
  action: PayloadAction<number>
) => void = (state, action) => {
  state.requisitionList = state.requisitionList.filter(
    (c) => c.id !== action.payload
  );
  state.selectedReqId = 0;
};

var copyPracticeRequisition: (
  state: State,
  action: PayloadAction<RequisitionData>
) => void = (state, action) => {
  const accessionNumber = randomNumber();
  var lastId = state.lastId + 1;
  const copiedRequisitiondata: RequisitionData = {
    ...action.payload,
    id:lastId,
    accessionNumber: accessionNumber,
  };
  state.lastId = lastId;
  state.requisitionList = [copiedRequisitiondata, ...state.requisitionList];
  state.selectedReqId = copiedRequisitiondata.id??0;
};

var updatePracticeRequisition: (
  state: State,
  action: PayloadAction<RequisitionData>
) => void = (state, action) => {
  state.requisitionList = state.requisitionList.map((c) => {
    if (c.accessionNumber === action.payload.accessionNumber)
      return action.payload;
    else return c;
  });
};

var selectPracticeRequisition: (
  state: State,
  action: PayloadAction<number>
) => void = (state, action) => {
  state.selectedReqId = action.payload;
};

var clearAllPracticeRequisitions: (
  state: State,
  action: PayloadAction
) => void = (state, action) => {
  state.requisitionList = [];
  state.selectedReqId = 0;
  state.lastId = 0;
};

type State = {
  practiceRequisitionList: ReducerState<RequisitionData[]>;
  getRequisition: ReducerState<RequisitionData>;
  requisitionList: RequisitionData[];
  selectedReqId: number;
  lastId: number;
};

var defaultState: State = {
  getRequisition: { isLoading: false },
  practiceRequisitionList: { isLoading: false },
  requisitionList: [],
  selectedReqId: 0,
  lastId: 0,
};

var slice = createSlice({
  name: controlName,
  initialState: defaultState as State,
  reducers: {
    addPracticeRequisition,
    removePracticeRequisition,
    copyPracticeRequisition,
    updatePracticeRequisition,
    selectPracticeRequisition,
    clearAllPracticeRequisitions,
  },
  extraReducers: {
    ...getRequisition.reducer,
    ...getPracticeRequisitions.reducer,
  },
});

const ret = {
  reducer: {
    [controlName]: slice.reducer,
  },
  actions: {
    [controlName]: {
      ...slice.actions,
      getRequisition: getRequisition.action,
      getPracticeRequisitions: getPracticeRequisitions.action,
    },
  },
};

export default ret;
